import { useEffect, useState } from "react";
import FilterElement from "./FilterElement";
import cross from "../../asset/images/Cross.png";
const FilterRow = ({
  filterOptions,
  rules,
  groups,
  updateRules,
  deleteRules,
  choosedData,
  setFilterBtnFlag,
  index,
  childIndex,
  groupLoader,
  setFilterBtnFlg2,
}) => {
  const [firstChoose, setFirstChoose] = useState(rules?.args[0][0]?.key);
  const [firstOption, setFirstOption] = useState([choosedData]);
  const [ruleRow, setRuleRow] = useState(rules);
  console.log(rules, 'rules')
  // console.log("Rules Row==================>",rules)
  // const [crossFlag, setCrossFlag] = useState(false);
  const onSelect = (event, option) => {
    // console.log("onselect called", event, option);
    // console.log("valueOf_firstChoose",event,option)
    console.log("Event", event, rules);
    /* if (!event?.length) {
      return;
    } */
    if (option == "firstOption") {
      let temp = { ...rules };
      temp.args[0] = event;
      temp.args[1] = [];
      temp.args[2] = [];
      temp.args[3] = [];
      temp.operator = [];
      updateRules(temp);
      // setFilterBtnFlag(true);
      // setCrossFlag(true)
    }
    if (option == "secondOption") {
      let temp = { ...rules };
      if (event[0]?.isOptionOperator) {
        temp.operator = event;
      } else {
        temp.args[1] = event;
      }
      updateRules(temp);
      setFilterBtnFlag(true);
    }
    if (option == "thirdOption") {
      let temp = { ...rules };
      if (event[0]?.isOptionOperator) {
        temp.operator = event;
      } else {
        temp.args[2] = event;
      }
      updateRules(temp);
      setFilterBtnFlag(true);
    }
    if (option == "fourthOption") {
      let temp = { ...rules };
      temp.args[3] = event;
      updateRules(temp);
      setFilterBtnFlag(true);
    }
  };

  return (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "rgb(249 249 249)",
        margin: "5px 0px",
        border: "1px solid #e6e6e6",
        borderRadius: "4px",
        height: "80px",
        position: "relative",
      }}
    >
      {filterOptions && (
        <div className="" style={{ margin: "20px 15px" }}>
          <FilterElement
            options={filterOptions}
            value={rules.args[0]}
            key={0}
            onChange={(value) => {
              setFirstChoose(value[0]?.key);
              onSelect(value, "firstOption");
            }}
            choosedData={firstOption}
            rules={rules}
            setFilterBtnFlg2={setFilterBtnFlg2}
          />
        </div>
      )}
      {rules.args[0][0] && rules.args[0][0].options ? (
        <div className="" style={{ margin: "20px 5px" }}>
          <FilterElement
            options={rules.args[0][0].options}
            key={1}
            value={
              rules.args[0][0].options[0].isOptionOperator
                ? rules.operator
                : rules.args[1]
            }
            placeholder={rules.args[0][0].placeholder}
            onChange={(value) => onSelect(value, "secondOption")}
            setFilterBtnFlg2={setFilterBtnFlg2}
          />
        </div>
      ) : (
        ""
      )}

      {rules.args[1][0] ? (
        <div className="" style={{ margin: "20px 5px" }}>
          <FilterElement
            options={rules.args[1][0].options}
            value={
              rules?.args[1][0]?.options[0]?.isOptionOperator
                ? rules.operator
                : rules.args[1]
            }
            placeholder={rules?.args[1][0].placeholder}
            onChange={(value) => {
              console.log('value 128', value);
              onSelect(value, "thirdOption")
            }}
            setFilterBtnFlg2={setFilterBtnFlg2}
          />
        </div>
      ) : (
        ""
      )}
      {rules.operator?.length && rules?.operator[0]?.hasInput ? (
        <div className="" style={{ margin: "20px 5px" }}>
          <FilterElement
            // options={rules.args[0][0]?.key == "groups" ? groups : null}
            firstChoose={firstChoose}
            groupLoader={groupLoader}
            groups={groups}
            value={rules?.args[3]}
            placeholder={rules?.operator[0]?.placeholder}
            onChange={(value) => onSelect(value, "fourthOption")}
            index={index}
            childIndex={childIndex}
            ruleRow={ruleRow}
            specialRule={rules}
            setFilterBtnFlg2={setFilterBtnFlg2}
          />
        </div>
      ) : (
        ""
      )}
      {rules?.args[0][0] && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: "1rem",
            width: "100%",
            fontSize: "1.2em",
          }}
        >
          <span
            onClick={() => {
              deleteRules();
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={cross} />
          </span>
        </div>
      )}
    </div>
  );
};

export default FilterRow;
