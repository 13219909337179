import { useContext, useEffect, useState } from "react";
import "./SegmentComponent.css";
import { GoChevronDown } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import RenameSVG from "../../../../../../asset/svg/RenameSVG";
import DeleteSVG from "../../../../../../asset/svg/DeleteSVG";
import { MyContext } from "../../../../../../ContextAPIStore/CreateContext";
import RenameInputModal from "../../../../../../modal/renameInputModal/RenameInputModal";
import GeneralConfirmModal from "../../../../../../modal/generalConfirmModal/GeneralConfirmModal";
import { updateViewNamePatchAPI } from "../../../../../../services/servicesAPI";
import { deleteViewAPI } from "../../../../../../services/servicesAPI";
import {
  returnDateFromTimestamp,
  returnTimeFromTimestamp,
} from "../../../../../../utils/Utils";
import { toast } from "react-toastify";

const SegmentComponent = ({
  index,
  item,
  setDeleteSegmentFlag,
  deleteSegmentFlag,
  setDeleteSegmentItem,
  deleteSegmentItem,
  getAllviewsFunction,
  filterSortingPayloadGroup,
  segmentFinalData,
  selectAllFlag,
  setSelectAllFlag
}) => {
  const [isCheckedFlag, setIsCheckedFlag] = useState(false);
  const [moreDropdownFlag, setMoreDropdownFlag] = useState(false);
  const [renameModalFlag, setRenameModalFlag] = useState(false);
  const [delteModalFlag, setDeleteModalFlag] = useState(false);

  const constConfigData = useContext(MyContext);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("inside useeffct");
    setIsCheckedFlag(item?.isChecked);
  }, [item?.isChecked]);

  const handleToggleChecked = (e, index, itemId) => {
    if (e.target.checked) {
      // If checkbox is checked, add itemId to deleteSegmentItem array
      let res = [...deleteSegmentItem];
      res.push(itemId);
      setDeleteSegmentItem(res);
      // setSelectAllFlag(true)
    } else {
      // If checkbox is unchecked, remove itemId from deleteSegmentItem array
      let res = deleteSegmentItem.filter(id => id !== itemId);
      setDeleteSegmentItem(res);
    }
    
    // Toggle isCheckedFlag and deleteSegmentFlag
    setIsCheckedFlag(!isCheckedFlag);
    setDeleteSegmentFlag(!deleteSegmentFlag);
  };

  const handleNavigateSegment = (data) => {
    console.log("clicked view segment", data);
    // navigate(`/contacts/segment/${item?._id}`, { state: { id:item?._id} });
    navigate(`/contacts?type=view&id=${data?._id}`);
  };

  const UpdateViewNamePatchFunction = async (id, payload) => {
    try {
      const res = await updateViewNamePatchAPI(id, payload);
      if (res.success) {
        toast.success(res.message);
        getAllviewsFunction(filterSortingPayloadGroup)
      } else {
        toast.error(res.message);
      }
      // getAllviewsFunction(filterSortingPayloadGroup);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRename = (name) => {
    console.log("id and name", item?._id, name);
    UpdateViewNamePatchFunction(item?._id, { name: `${name}` });
  };

  const deleteViewApiFunction = async (payload) => {
    try {
      const res = await deleteViewAPI({
        viewsId: [item?._id],
      });
      if (res.success) {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
      getAllviewsFunction(filterSortingPayloadGroup);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = () => {
    deleteViewApiFunction();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown = target.closest(".view-more-dropdown");
      if (!isInsideDropdown) {
        setMoreDropdownFlag(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    // Cleanup function to remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="segmentcomponent">
      {renameModalFlag && (
        <RenameInputModal
          headingText="Rename the view"
          handleSave={handleRename}
          value={item?.name}
          placeholder=" "
          onClose={() => {
            setRenameModalFlag(false);
          }}
        />
      )}
      {delteModalFlag && (
        <GeneralConfirmModal
          onSuccess={handleDelete}
          headingText={`Please confirm`}
          descText={`Are you sure you want to delete view: ${item?.name}`}
          onclose={() => {
            setDeleteModalFlag(false);
          }}
        />
      )}
      <div className="segmentcomponent-left">
        <div className="segmentcomponent-left-1">
          <input
            checked={deleteSegmentItem.includes(item?._id)}
            onChange={(e) => {
              handleToggleChecked(e,index, item?._id);
            }}
            style={{
              marginRight: "10px",
              height: "17px",
              width: "17px",
              border: "2px solid #D1D5DB",
            }}
            type="checkbox"
          />
        </div>
        <div className="segmentcomponent-left-2">
          <h3>{item?.name}</h3>
          <p>
            Created{" "}
            <abbr id="group-abbr">
              {returnDateFromTimestamp(item?.createdAt)}{" "}
              <span className="group-abbr-span">
                {returnTimeFromTimestamp(item?.createdAt)}
              </span>{" "}
            </abbr>
          </p>
          <button
            onClick={() => {
              handleNavigateSegment(item);
            }}
          >
            {constConfigData?.View}
          </button>
        </div>
      </div>
      <div className="segmentcomponent-right">
        <div
          style={{ borderLeft: "2px solid #E5E7EB" }}
          className="segmentcomponent-right-1"
        >
          <div className="segmentcomponent-right-1-1">
            <p>{constConfigData?.Contacts}</p>
          </div>
          <div className="view-more-dropdown" style={{ position: "relative" }}>
            <div className="segmentcomponent-right-1-2">
              <p>{item?.contacts}</p>
              <p>{item?.subscribersCount}</p>
              <div
                onClick={() => {
                  setMoreDropdownFlag(!moreDropdownFlag);
                }}
              >
                <button>{constConfigData?.More}</button>
                <GoChevronDown size={18} />
              </div>
            </div>
            {moreDropdownFlag && (
              <div className="more-dropdown-div">
                <ul>
                  <li
                    onClick={() => {
                      setMoreDropdownFlag(false);
                      setRenameModalFlag(true);
                    }}
                  >
                    <RenameSVG />{" "}
                    <span
                      style={{
                        marginLeft: "10px",
                        fontWeight: "400",
                        color: "#404040",
                      }}
                    >
                      {constConfigData?.Rename}
                    </span>
                  </li>
                  {/* <li>
                    <SplitSVG/>{" "}
                    <span style={{ marginLeft: "10px",fontWeight:'400',color:'#404040' }}>{constConfigData?.Split}</span>{" "}
                  </li>
                  <li>
                    <WarmupSplitSVG/>{" "}
                    <span style={{ marginLeft: "10px",fontWeight:'400',color:'#404040' }}>{constConfigData?.Warmup_split}</span>{" "}
                  </li> */}
                  <p style={{ border: "1px solid #E5E7EB" }}></p>
                  <li
                    onClick={() => {
                      setMoreDropdownFlag(false);
                      setDeleteModalFlag(true);
                    }}
                  >
                    <DeleteSVG />{" "}
                    <span
                      style={{
                        marginLeft: "10px",
                        fontWeight: "400",
                        color: "#404040",
                      }}
                    >
                      {constConfigData?.Delete}
                    </span>{" "}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          style={{ borderLeft: "2px solid rgba(32, 129, 226, 1)" }}
          className="segmentcomponent-right-1"
        >
          <div className="segmentcomponent-right-1-1">
            <p>{constConfigData?.Emails}</p>
          </div>
          <div className="segmentcomponent-right-1-2">
            <p>{item?.emails}</p>
            {/* <div>
              <button>{constConfigData?.More}</button>
              <GoChevronDown size={18} />
            </div> */}
          </div>
        </div>
        <div
          style={{ borderLeft: " 2px solid rgba(253, 140, 4, 1)" }}
          className="segmentcomponent-right-1"
        >
          <div className="segmentcomponent-right-1-1">
            <p>{constConfigData?.Addresses}</p>
          </div>
          <div className="segmentcomponent-right-1-2">
            <p>{item?.addresses}</p>
            {/* <div>
              <button>{constConfigData?.More}</button>
              <GoChevronDown />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SegmentComponent;
