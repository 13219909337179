import { useNavigate } from "react-router-dom";
import AccountsNavbar from "../accountsNavbar/AccountsNavbar";
import "./EmsConnection.css";
import { accountsData, data } from "../../../asset/documentfiles/Documents";
import {
  handleIsCheckedOddOrEven,
  returnDateFromTimestamp,
} from "../../../utils/Utils";
import ToggleOnSVG from "../../../asset/svg/ToggleOnSVG";
import EditSVG from "../../../asset/svg/EditSVG";
import {
  deleteEMSConn,
  emsConnectionPostAPI,
} from "../../../services/servicesAPI";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Page,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { DataManager, UrlAdaptor } from "@syncfusion/ej2-data";
import ToggleOffSVG from "../../../asset/svg/ToggleOffSVG";
import ActionEditModal from "../actionEditModal/ActionEditModal";
import { useContext, useEffect, useState } from "react";
import GeneralConfirmModal from "../../../modal/generalConfirmModal/GeneralConfirmModal";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import ActivitySVG from "../../../asset/svg/ActivitySVG";
import DeleteWithRedOutlineSVG from "../../../asset/svg/DeleteWithRedOutlineSVG";
import FilterListSVG from "../../../asset/svg/FilterListSVG";
import { getToken } from "../../../utils/getToken";
import { toast } from "react-toastify";
import { getEMSAPIType } from "../../../services/servicesAPI";
import { updateIsActiveEMS } from "../../../services/servicesAPI";
import { testConnectionGetAPI } from "../../../services/servicesAPI";

const EmsConnection = () => {
  const navigate = useNavigate();
  const constConfigData = useContext(MyContext);
  const pageSettings = { pageSize: 25 };
  const sortSettings = {
    columns: [
      // { field: 'label', direction: 'Ascending' }
    ],
  };
  const FilterOptions = {
    type: "CheckBox",
    enableInfiniteScrolling: true,
  };
  const [actionEditModalFlag, setActionEditModalFlag] = useState(false);
  const [actionDeleteModalFlag, setActionDeleteModalFlag] = useState(false);
  const [accountTableData, setAccountTableData] = useState([]);
  const [SyncFuseData, setSyncFuseData] = useState();
  const [editVal, setEditVal] = useState([]);
  const [formValue, setFormValue] = useState({});
  const [allData, setAllData] = useState([]);
  const [payload, setPayload] = useState({
    requiresCounts: true,
    skip: 0,
    take: 25,
  });
  const [changeTrack, setChangeTrack] = useState(false)

  const emsConnectionPostAPIFun = async (payload) => {
    const response = await emsConnectionPostAPI(payload);
    if (response.success) {
      console.log(response);
      setAllData(response.data.result);
    }
  };

  useEffect(() => {
    emsConnectionPostAPIFun(payload);
  }, [changeTrack]);

  const handleNavigate = (path, formValue) => {
    console.log("FormValue", formValue);
    let obj = {
      emsConnId: formValue?._id,
      label: formValue?.label,
      emsAPITypeId: formValue?.emsAPITypeI,
      username: formValue?.username,
      url: formValue?.url,
      secretKey: formValue?.secretKey,
      connectionString: formValue?.connectionString,
      isActive: formValue?.isActive,
      type: formValue?.type,
      lastActivity: "Lorem ispum lorem ispum",
      connection: formValue?.connectionStatus,
    };

    navigate(path, { state: obj });
  };

  const syncTableConnection = (dataItem) => {
    let obj = {};

    if (dataItem === "Bad") {
      obj.color = "red";
      obj.bgColor = "#EF44441A";
    } else if (dataItem === "Green") {
      obj.color = "green";
      obj.bgColor = "#0D8C1A1A";
    } else if (dataItem === "Unknown") {
      obj.color = "grey";
      obj.bgColor = "#9898981A";
    }
    return obj;
  };

  let url = `${process.env.REACT_APP_BASE_URL}/account/getAllEMSConn`;

  const SyncFun = async () => {
    const SyncData = new DataManager({
      adaptor: new UrlAdaptor(),
      url: url,
      headers: [
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      ],
    });
    setSyncFuseData(SyncData);
  };

  useEffect(() => {
    SyncFun();
  }, []);

  /* useEffect(() => {
    const data = new DataManager({
      adaptor: new UrlAdaptor(),
      url: `${process.env.REACT_APP_BASE_URL}/account/getAllEMSConn`,
      headers: [
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      ],
    });
    setAccountTableData(data);
  }, []); */

  const handleDelete = async () => {
    console.log(formValue);
    let myPayload = {
      emsConnId: formValue?._id,
      label: formValue?.label,
      emsAPITypeId: formValue?.emsAPITypeI,
      username: formValue?.username,
      url: formValue?.url,
      secretKey: formValue?.secretKey,
      connectionString: formValue?.connectionString,
      isActive: formValue?.isActive,
    };
    console.log("payload", myPayload);
    const response = await deleteEMSConn(myPayload?.emsConnId, myPayload);
    if (response.success) {
      setChangeTrack(!changeTrack)
      toast.success(response.message);
      SyncFun();
    } else {
      toast.error(response.message);
    }
    console.log("Res of Delete", response);
  };

  const handleToggleClick = async (isActive, value) => {
    console.log(isActive, value);
    const res = await updateIsActiveEMS({
      emsConnId: value?._id,
      isActive: isActive,
    });
    if (res.success) {
      setChangeTrack(!changeTrack)
      toast.success(res.message);
      SyncFun();
    } else {
      toast.error(res.message);
    }
  };

  return (
    <div className="ems">
      {actionEditModalFlag && (
        <ActionEditModal
          changeTrack = {changeTrack}
          setChangeTrack = {setChangeTrack}
          editVal={editVal}
          SyncFun={SyncFun}
          setActionEditModalFlag={setActionEditModalFlag}
          onClose={() => {
            setActionEditModalFlag(false);
          }}
        />
      )}
      {actionDeleteModalFlag && (
        <GeneralConfirmModal
          onSuccess={handleDelete}
          handleDelete={handleDelete}
          onclose={() => {
            setActionDeleteModalFlag(false);
          }}
          headingText={"Please confirm"}
          descText={`Are you sure you want to delete connection: ${formValue?.label}?`}
        />
      )}

      <div style={{ height: "35px" }}></div>
      <div className="ems-heading">
        <h2
          style={{
            fontWeight: "600",
            fontSize: "2.4em",
            letterSpacing: "0.8px",
          }}
        >
          {constConfigData?.Accounts}
        </h2>
        <div
          onClick={() => {
            handleNavigate("/accounts/add-new-account");
          }}
          className="ems-heading-btn"
        >
          <p
            style={{
              fontWeight: "550",
              fontSize: "14px",
              padding: "0.13rem 0.5rem",
              color: "white",
            }}
          >
            {constConfigData?.Create_Account}
          </p>
        </div>
      </div>
      <div style={{ height: "30px" }}></div>
      <AccountsNavbar />
      <div style={{ height: "35px" }}></div>
      <div className="ems-content">
        <div className="ems-content-heading">
          <h1>{constConfigData?.EMS_Connection}</h1>
        </div>
        <GridComponent
          style={{ border: "none", padding: "0px 10px" }}
          // height={282}
          pageSettings={pageSettings}
          allowSorting={true}
          sortSettings={sortSettings}
          allowPaging={true}
          dataSource={allData}
          allowFiltering={true}
          filterSettings={FilterOptions}
          
        >
          <ColumnsDirective>
            <ColumnDirective
              // field="label"
              headerText="Label"
              width={`${100 / 7}%`}
              textAlign="left"
              template={(props) => {
                return (
                  <span
                    onClick={() => {
                      handleNavigate("edit-account", props);
                    }}
                    style={{ color: "#2081E3", cursor: "pointer" }}
                  >
                    {props.label}
                  </span>
                );
              }}
              // headerTemplate={(props) => (
              //   <div className="sync-table-header-ems">
              //     <p>{props.headerText}</p>
              //     <div className="sync-table-header-ems-icon">
              //       <FilterListSVG />
              //     </div>
              //   </div>
              // )}
            />
            <ColumnDirective
              field="type"
              headerText="Type"
              width={`${100 / 7}%`}
              textAlign="left"
              // headerTemplate={(props) => (
              //   <div className="sync-table-header-ems">
              //     <p>{props.headerText}</p>
              //     <div className="sync-table-header-ems-icon">
              //       <FilterListSVG />
              //     </div>
              //   </div>
              // )}
            />
            <ColumnDirective
              // field="username"
              headerText="Username"
              width={`${100 / 6}%`}
              textAlign="left"
              // headerTemplate={(props) => (
              //   <div className="sync-table-header-ems">
              //     <p>{props.headerText}</p>
              //     <div className="sync-table-header-ems-icon">
              //       <FilterListSVG />
              //     </div>
              //   </div>
              // )}
              template={(props) => {
                return (
                  <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                    {props.username}
                  </p>
                );
              }}
            />
            <ColumnDirective
              // field="lastActivity"
              headerText="Last Activity"
              width={`${100 / 6}%`}
              textAlign="left"
              template={(props) => {
                const date = new Date(props.lastActivity);
                const formattedDate = date.toLocaleDateString();
                return <span>{returnDateFromTimestamp(props?.createdAt)}</span>;
              }}
            />
            <ColumnDirective
              allowSorting={false}
              // field="isActive"
              headerText="Is Active"
              width={`${100 / 7}%`}
              textAlign="center"
              template={(props) => {
                return (
                  <div
                    onClick={() => {
                      handleToggleClick(!props?.isActive, props);
                    }}
                  >
                    {props?.isActive ? (
                      <ToggleOnSVG height={25} />
                    ) : (
                      <ToggleOffSVG height={25} />
                    )}
                  </div>
                );
              }}
            />
            <ColumnDirective
              // field="connection"
              headerText="Connection"
              width={`${100 / 6}%`}
              textAlign="center"
              template={(props) => {
                let obj1 = syncTableConnection(props.connection);
                const backgroundColor =
                  props?.connectionStatus === "GOOD"
                    ? "good-background"
                    : "bad-background";
                return (
                  <div>
                    {props.ConnectionStatus === "GOOD" ? (
                      <div className={backgroundColor}>
                        {props?.connectionStatus}
                      </div>
                    ) : (
                      <div className={backgroundColor}>
                        {props?.connectionStatus}
                      </div>
                    )}
                  </div>
                );
              }}
              // headerTemplate={(props) => (
              //   <div className="sync-table-header-ems">
              //     <p>{props.headerText}</p>
              //     <div className="sync-table-header-ems-icon">
              //       <FilterListSVG />
              //     </div>
              //   </div>
              // )}
            />
            <ColumnDirective
              allowSorting={false}
              // field="connection"
              headerText="Action"
              width={`${100 / 7}%`}
              textAlign="center"
              template={(val) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      onClick={() => {
                        setActionEditModalFlag(true);
                        setEditVal(val);
                      }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <EditSVG />
                    </div>
                    <div
                      onClick={() => {
                        setActionDeleteModalFlag(true);
                        setFormValue(val);
                      }}
                      style={{
                        cursor: "pointer",
                        margin: "0px 5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DeleteWithRedOutlineSVG />
                    </div>
                    <div
                      onClick={() => {
                        navigate("/accounts/auto-activities/");
                      }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ActivitySVG />
                    </div>
                  </div>
                );
              }}
            />
          </ColumnsDirective>
          <Inject services={[Page, Sort, Filter]} />
        </GridComponent>
      </div>
    </div>
  );
};

export default EmsConnection;

// export const handleHeaderTextSync = async (props)=>{
//   return <div className="sync-table-header">
//   <p>{props.headerText}</p>
//   <div className="sync-table-header-icon" >
//   <FilterListSVG  />
//   </div>
// </div>
// }
