import React, { useContext, useEffect, useState } from "react";
import "./Notifications.css";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import { notificationGetAPI } from "../../../services/servicesAPI";
import { toast } from "react-toastify";
import { returnDateAndTimeFromTimestamp, returnDateFromTimestamp, returnTimeFromTimestamp } from "../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../../components/loaderComponent/LoaderComponent";
const Notifications = () => {

  const navigate = useNavigate()


    const constConfigData = useContext(MyContext)
    const [notificationData,setNotificationData] = useState([])
    const [pagingAllData,setPagingAllData] = useState({})
    const [pagingData,setPagingData] = useState({page:1,limit:25,skip:0})
    const [loader,setLoader] = useState(false)
    
    
    

    const btnStyles ={
            backgroundColor: 'rgb(242,243,245)',  
            color: '#333' ,

    }

    const disabledBtnStyles = {
        backgroundColor: 'rgb(229,231,235)',  
        color: 'rgb(164,164,165)' ,
      };
    
      const getAllNotificationAPI = async (payload)=>{
        setLoader(true)
        let response = await notificationGetAPI(payload);
        console.log("notification=========>",response?.data?.result)
        if(response.success){
            // toast.success(response.message)
            setNotificationData(response?.data?.result)
            let temp = {
                hasNextPage:response?.data?.hasNextPage,
                hasPrevPage:response?.data?.hasPrevPage,
                count:response?.data?.count
            }
            setPagingAllData(temp)
        }else{
            toast.error(response.message)
        }
        setLoader(false)
        
      }

      useEffect(()=>{
        getAllNotificationAPI(pagingData)

      },[])
      const handlePagingApiData = async (value)=>{
        if(value === 1){
          if(pagingData.page >= 2){
            let page = pagingData.page - 1
            let limit = pagingData.limit
            let skip = pagingData.skip - pagingData.limit
            let payload = {page:page,limit:limit,skip:skip}
            setPagingData(payload)
            await getAllNotificationAPI(payload);
          }
      
        }else{
          if((pagingAllData.count > (10 * pagingData.page)))
          {
            let page = pagingData.page + 1
            let limit = pagingData.limit
            let skip = pagingData.skip + pagingData.limit
            let payload = {page:page,limit:limit,skip:skip}
            setPagingData(payload)
            await getAllNotificationAPI(payload);
           }
          }
          
      }

      const handleNavigate = (pathRedirectTo,isExternalRedirect)=>{
        console.log("path reditect to====>",pathRedirectTo)
        if(isExternalRedirect){
          window.open(`${pathRedirectTo}`, '_blank');
        }else{
          navigate(`/${pathRedirectTo}`)
        }
        
      }

     
  return (
    <div className="main-notification-container">
      {
      loader && <LoaderComponent />
      }
        {
            notificationData.length > 0 ?<div>
      <div className="notification-heading">
        <h2>{constConfigData?.Notifications}</h2>
      </div>
      <div style={{ height: "20px" }}></div>
      
      {
        notificationData.map((item,index)=>{
            return <div onClick={()=>{handleNavigate(item?.redirectTo,item?.isExternalRedirect)}} className="notifictaion-items" key={index}>
            <p className="item-one">{item.message}</p>{" "}
            <p className="item-two">{returnDateAndTimeFromTimestamp(item.createdAt)}</p>
            {/* <p className="item-two">{returnDateFromTimestamp(item.createdAt)}<span style={{marginLeft:'1em'}}>{returnTimeFromTimestamp(item.createdAt)}</span></p> */}
       </div>
        })
      }
      <div  className="notification-btn-section">
         <p id="page-num">{constConfigData?.Showing} <span >{(pagingData.limit*pagingData.page)-(pagingData.limit-1)}</span>{constConfigData?.to}<span>{(pagingData.page*pagingData.limit)>pagingAllData?.count?pagingAllData?.count:(pagingData.page*pagingData.limit)}</span>{constConfigData?.of}<span >{pagingAllData.count} </span>{constConfigData?.results}</p>
         <div >
            <button className="prev-btn" onClick={()=>{handlePagingApiData(1)}}  disabled={pagingAllData?.hasPrevPage ? false:true } style={ !pagingAllData?.hasPrevPage ? disabledBtnStyles : btnStyles}>{constConfigData?.Previous}</button>
            <button className="next-btn" onClick={()=>{handlePagingApiData(2)}} disabled={pagingAllData?.hasNextPage ? false:true } style={ !pagingAllData?.hasNextPage  ? disabledBtnStyles : btnStyles}>{constConfigData?.Next}</button>
         </div>
      </div>
      </div>
      :<div style={{display:"flex",justifyContent:"center",alignItems:"center",height:"35vh"}}>
        <p style={{fontSize:"1.2em"}}>No notification yet.</p>
      </div>
      }


    </div>

  );
};

export default Notifications;
