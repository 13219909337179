import { useContext, useEffect, useState } from "react";
import "./Group.css";
import { IoSearchOutline} from "react-icons/io5";
import { GoChevronDown } from "react-icons/go";
import { HiOutlineBarsArrowDown,HiOutlineBarsArrowUp } from "react-icons/hi2";
import GroupComponents from "./groupComponents/GroupComponents";
import GeneralConfirmModal from "../../../../../modal/generalConfirmModal/GeneralConfirmModal";
import AddGroupModal from "./addGroupModal/AddGroupModal";
import SubscriberNavbar from "../../../../../components/subscriberNavbar/SubscriberNavbar";
import { deleteGroupByIDDeleteAPI, groupGetAPI, postMergeGroupPostAPI, postSplitGroupPostAPI, postWarmupSplitGroupPostAPI, updateGroupPutAPI } from "../../../../../services/servicesAPI";
import { toast } from "react-toastify";
import { MyContext } from "../../../../../ContextAPIStore/CreateContext";
import LoaderComponent from "../../../../../components/loaderComponent/LoaderComponent";
import MergeModal from "../../../../../modal/mergeModal/MergeModal";

const Group = (props) => {
  const [groupInitialData, setGroupInitialData] = useState([]);
  const [groupFinalData,setGroupFinalData]=useState([])
  const [selectAllFlag,setSelectAllFlag]= useState(false)
  const [inputSearchGroup,setInputSearchGroup] = useState("")
  const [deleteModalFlag,setDeleteModalFlag] = useState(false)
  const [deleteGroupFlag,setDeleteGroupFlag] = useState(false)
  const [deleteGroupItem,setDeleteGroupItem] = useState([])
  const [addGroupmodalFlag,setAddGroupModalFlag] = useState(false)
  const [dateCreatedDropdownFlag, setDateCreatedDropdownFlag] = useState(false);
  const [reverseSortingFlag,setReverseSortingFlag] = useState(false)
  const [pagingData,setPagingData] = useState({page:1,limit:25})
  const [totalCount,setTotalCount] = useState()
  const [prevNextButton,setPrevNextButton] = useState({})
  const [loader,setLoader] = useState(false)
  const [mergeGroupModalFlag,setMergeGroupModalFlag] = useState(false)
  const [filterDataValue,setFilterDataValue] = useState("Date created")
  const [filterSortingPayloadGroup,setFilterSortingPayloadGroup] = useState({"sortBy":"createdAt","order":"DESC"
})
const [refreshPage,setRefreshPage] = useState(false)
const [searchBool, setSearchBool] = useState()

  const constConfigData = useContext(MyContext)

  useEffect(() => {
    getGroupAPI(pagingData,filterSortingPayloadGroup)
  }, []);
  useEffect(()=>{

  },[groupFinalData])
  useEffect(()=>{

  },[setTotalCount])


  const getGroupAPI = async (payload,filterData)=>{
    setLoader(true)
    if(inputSearchGroup.length > 0){
      filterSortingPayloadGroup.textSearch = inputSearchGroup
    }else{
      delete filterSortingPayloadGroup.textSearch
    }
    const response = await groupGetAPI(payload,filterData)
    if (response.success) {
      setTimeout(()=>{
      setLoader(false)
      },0)
        setGroupInitialData(response?.data?.data?.result)
        setGroupFinalData(response?.data?.data?.result)
        setTotalCount(response?.data?.data?.count)
        let obj = {}
        obj.prev = response?.data?.data?.hasPrevPage
        obj.next = response?.data?.data?.hasNextPage
        setPrevNextButton(obj)

    } else {
      toast.error(response.message)
      setLoader(false)
    }
    
  }

  const handleSelectAllChange = ()=>{
    if(!selectAllFlag){
      let temp = groupFinalData.map((item)=>{
        item.isChecked = true
        return item
      })

      setGroupFinalData(temp)
      setDeleteGroupFlag(true)
      setSelectAllFlag(true)
    let tempIndex = groupFinalData.map((item)=>{
      return item._id
    })
    setDeleteGroupItem(tempIndex)
    
      
    }else{
      let temp = groupFinalData.map((item)=>{
        item.isChecked = false
        return item
      })
      setGroupFinalData(temp)
      setDeleteGroupFlag(false)
      setSelectAllFlag(false)
    setDeleteGroupItem([])
    }
  }

  const handleSearchGroup = ()=>{
    setSearchBool(true)
    getGroupAPI(pagingData,filterSortingPayloadGroup)
  }
  

const handleCloseAddGroupModalCreate = ()=>{
  getGroupAPI(pagingData,filterSortingPayloadGroup)
  setAddGroupModalFlag(false)
}

const handleUpdateGroupAPI = async (item,renameInputValue)=>{
  let response = await updateGroupPutAPI(item._id,{name:renameInputValue})
  if(response.success){
    toast.success(response.message)
    getGroupAPI(pagingData,filterSortingPayloadGroup)
  }else{
    toast.error(response.message)
  }
}
const handleDeleteMultipleGroupAPI = async ()=>{
  let response = await deleteGroupByIDDeleteAPI(deleteGroupItem)
  if(response.success){
    setDeleteGroupItem([])
    toast.success(response.message)
   await getGroupAPI(pagingData,filterSortingPayloadGroup)
   setRefreshPage(!refreshPage)
  }else{
    toast.error(response.message)
  }

}

const handleDeleteGroupAPI = async (item)=>{
  let tempArr = [];
  tempArr.push(item._id)
  let response = await deleteGroupByIDDeleteAPI(tempArr)
  if(response.success){
    toast.success(response.message)
    getGroupAPI(pagingData,filterSortingPayloadGroup)
  }else{
    toast.error(response.message)
  }
}

const handleReverseData = async ()=>{
  
  if(reverseSortingFlag){
    filterSortingPayloadGroup.order = "ASC"
  }else{
    filterSortingPayloadGroup.order = "DESC"
  }
  
  await getGroupAPI(pagingData,filterSortingPayloadGroup)
  setReverseSortingFlag(!reverseSortingFlag)
}

const handlePagingApiData = async (value)=>{
  if(value === 1){
    if(pagingData.page >= 2){
      let page = pagingData.page - 1
      let limit = pagingData.limit
      let payload = {page:page,limit:limit}
      setPagingData(payload)
      await getGroupAPI(payload,filterSortingPayloadGroup);
    }

  }else{
    if((totalCount > (10 * pagingData.page)))
    {
      let page = pagingData.page + 1
      let limit = pagingData.limit
      let payload = {page:page,limit:limit}
      setPagingData(payload)
      await getGroupAPI(payload,filterSortingPayloadGroup);
     }
    }
    
}

const handleMergeGroupAPI = async (checkboxValue,inputTextValue)=>{
  const temp = {
    "deleteGroups":checkboxValue,
    "groupIds":deleteGroupItem,
    "newGroupName":inputTextValue
  }

  const response = await postMergeGroupPostAPI(temp);
  if(response.success){
    toast.success(response.message)
    await getGroupAPI(pagingData,filterSortingPayloadGroup)
   setRefreshPage(!refreshPage)
    

  }else{
    toast.error(response.message)
  }
}
  

useEffect(() => {
  const handleClickOutside = (event) => {
    let target = event.target;
    let isInsideDropdown = target.closest('.group-content-1-2-2') 
    if (!isInsideDropdown) {
      setDateCreatedDropdownFlag(false);
    }
  };

  document.addEventListener('click', handleClickOutside);
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []); 

  const handleFilterGroupData = (listItem)=>{
    setFilterDataValue(listItem.key)
    if(listItem.value === 1){
      filterSortingPayloadGroup.sortBy = "createdAt"
      getGroupAPI(pagingData,filterSortingPayloadGroup)

    } else if(listItem.value === 2){
      filterSortingPayloadGroup.sortBy = "name"
      getGroupAPI(pagingData,filterSortingPayloadGroup)
    } else{
      filterSortingPayloadGroup.sortBy = "contactCount"
      getGroupAPI(pagingData,filterSortingPayloadGroup)
    }

  }

  const handleSplitGroupAPI = async (item,inputValue)=>{

    let temp = {
      "groupId": item?._id,
      "noOfGroup":inputValue
    }
    const response = await postSplitGroupPostAPI(temp)

    if(response.success){
      toast.success(response.message)
      await getGroupAPI(pagingData,filterSortingPayloadGroup)
      setRefreshPage(!refreshPage)

    }else{
      toast.error(response.message)
    }
  }

  const handleWarmupSplitGroupAPI = async (item)=>{

    const response = await postWarmupSplitGroupPostAPI({"groupId": item?._id})
    if(response.success){
      toast.success(response.message)
      await getGroupAPI(pagingData,filterSortingPayloadGroup)
      setRefreshPage(!refreshPage)

    }else{
      toast.error(response.message)
    }
  }




  return (
    <div className="group">

    <div style={{ height: "35px" }}></div>
    {
        loader && <LoaderComponent />
    }
      <div className="group-heading">
        <div>
          <h1 style={{fontWeight:"600"}}>{constConfigData?.Contacts}</h1>
        </div>
        <div className="group-heading-right">
          
          <div onClick={()=>{setAddGroupModalFlag(true)}} className="group-heading-right-2">
          <p style={{fontWeight:"600",fontSize:"14px",padding:"0.2em 0.1em" ,letterSpacing:'0.7px', color:"white"}}>{constConfigData?.Create_Group}</p>
          </div>
        </div>
      </div>
      <div style={{ height: "30px" }}></div>
      <SubscriberNavbar /> 
      <div style={{ height: "30px" }}></div>

      {
        addGroupmodalFlag && <AddGroupModal headingText={"Create group"} groupFinalData={groupFinalData} setGroupFinalData={setGroupFinalData} onclose={()=>{setAddGroupModalFlag(false)}} handleCloseAddGroupModalCreate={handleCloseAddGroupModalCreate} />
      }
      {
            deleteModalFlag && <GeneralConfirmModal headingText={"Please confirm"} onSuccess={handleDeleteMultipleGroupAPI} onclose={()=>{setDeleteModalFlag(false)}} descText={`Are you sure you want to delete ${deleteGroupItem.length} groups ?`} />
      }
      {
        mergeGroupModalFlag && <MergeModal onSuccess={handleMergeGroupAPI} placeholder={"Enter group name"} descText2={"Delete merged group"} headingText={"Merge selected groups"} descText={"Merge group name"} onClose={()=>{setMergeGroupModalFlag(false)}} />
      }
      {(groupFinalData?.length > 0 || searchBool)  ? (
        <div className="group-content">
          <div className="group-content-1">
            <div className="group-content-1-1">
              {
                (groupFinalData!=0) && <>
                <input checked={deleteGroupItem.length === groupFinalData.length?true:false} onClick={()=>{handleSelectAllChange()}}  style={{ marginRight: "8px",border:'2px solid #D1D5DB',height:'18px',width:'18px'}} type="checkbox" />
              <p>{constConfigData?.Select_All}</p>
                </>
              }
              
              
              {
                deleteGroupItem.length>1 && <div style={{marginLeft:"1.5vw"}}>
                <button style={{cursor:"pointer",backgroundColor:"rgb(229,231,235)",padding:"1.5vh 1vw", border:"none", borderRadius:"4px", fontSize:"1em", fontWeight:"550",color:"#393A3B"}} onClick={()=>{setMergeGroupModalFlag(true)}} >Merge</button>
              </div>
              }

              {
                deleteGroupItem.length>0 &&  <div style={{marginLeft:"1.5vw"}}>
                <button style={{cursor:"pointer",backgroundColor:"rgb(229,231,235)",padding:"1.5vh 1vw", border:"none", borderRadius:"4px", fontSize:"1em", fontWeight:"550",color:"#393A3B"}} onClick={()=>{setDeleteModalFlag(true)}}>Delete{" "}({deleteGroupItem.length})</button>
              </div>

              }
            </div>
            
            <div className="group-content-1-2">
              <div className="group-content-1-2-1">
                <input type="text" placeholder="Search group" value={inputSearchGroup} onChange={(e)=>{setInputSearchGroup(e.target.value)}} />
                <div style={{cursor:"pointer"}} onClick={()=>{handleSearchGroup()}}>
                  <IoSearchOutline  size={18}/>
                </div>
              </div>
              <div className="group-content-1-2-2">
              <div
                  onClick={() => {
                    setDateCreatedDropdownFlag(!dateCreatedDropdownFlag);
                  }}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                <p style={{color:'#404040',fontWeight:'400'}}>{filterDataValue}</p>
                <div>
                  <GoChevronDown size={18}/>
                </div>
                </div>
                {dateCreatedDropdownFlag && (
                  <div className="group-content-1-2-2-dropdown">
                    <ul className="group-content-1-2-2-dropdown-li">
                      <li
                      className="group-content-1-2-2-dropdown-li"
                        onClick={() => {
                          handleFilterGroupData({key:"Date Created",value:1})
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        Date created
                      </li>
                      <li
                      className="group-content-1-2-2-dropdown-li"
                        onClick={() => {
                          handleFilterGroupData({key:"Group name",value:2})
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        {constConfigData?.Group_Name}
                      </li>
                      <li
                      className="group-content-1-2-2-dropdown-li"
                        onClick={() => {
                          handleFilterGroupData({key:"Contacts",value:3})
                          setDateCreatedDropdownFlag(false);
                        }}
                      >
                        {constConfigData?.Contacts}
                      </li>
                      
                    </ul>
                  </div>
                )}
              </div>
              <div onClick={()=>{handleReverseData()}} className="group-content-1-2-3">
                {
                  reverseSortingFlag ? <HiOutlineBarsArrowUp size={18} /> : <HiOutlineBarsArrowDown size={18} />
                }
                
              </div>
            </div>
          </div>
          <div>
            {
              groupFinalData.map((item,index)=>{

                return <GroupComponents handleWarmupSplitGroupAPI={handleWarmupSplitGroupAPI} handleSplitGroupAPI={handleSplitGroupAPI} refreshPage={refreshPage} setRefreshPage={setRefreshPage} handleDeleteGroupAPI={handleDeleteGroupAPI} handleUpdateGroupAPI={handleUpdateGroupAPI} index={index} deleteGroupItem={deleteGroupItem} setDeleteGroupItem={setDeleteGroupItem} deleteGroupFlag={deleteGroupFlag} setDeleteGroupFlag={setDeleteGroupFlag} setSelectAllFlag={setSelectAllFlag} groupFinalData={groupFinalData} key={index} item={item} />
              })
            }

{
              groupFinalData == 0 && <p style={{textAlign:"center",padding:"1rem",color:"gray"}}>Group not found.</p>
            }
          </div>

          <div className="group-content-3">
            <div className="group-content-3-1"><p>Showing <span>{(pagingData.limit*pagingData.page)-(pagingData.limit-1)}</span> to 
            {" "}
            
            <span>{(pagingData.page*pagingData.limit)>totalCount?totalCount:(pagingData.page*pagingData.limit)}</span>
            {" "}
             of 
            
             {" "}
             <span>{totalCount}</span>
             {" "}
             results</p>
             </div>
            <div className="group-content-3-2">
              <button disabled={prevNextButton?.prev ? false : true} onClick={()=>{handlePagingApiData(1)}} style={{backgroundColor: prevNextButton?.prev ? "#E5E7EB" : "#F2F3F5",color:prevNextButton?.prev ?"#404040":'rgb(159,159,159)',cursor:prevNextButton?.prev?"pointer":"" ,padding:'12px 22px',fontSize:'1.2em'}} >{constConfigData?.Previous}</button>
              <button disabled={prevNextButton?.next ? false : true} onClick={()=>{handlePagingApiData(2)}}style={{backgroundColor: prevNextButton?.next ? "#E5E7EB" : "#F2F3F5",color:prevNextButton?.next ?"#404040":'rgb(159,159,159)' ,cursor:prevNextButton?.next?"pointer":"",padding:'12px 22px',fontSize:'1.2em'}}>{constConfigData?.Next}</button>
            </div>
            

          </div>
          
          
        </div>
      ) : (
        <div className="group-no-data">
          <p
            style={{
              color: "rgba(38, 38, 38, 1),",
              fontSize: "1.5em",
              fontWeight: "600",
            }}
          >
            {constConfigData?.You_dont_have_any_group}
          </p>
          <p style={{color:'#404040B2'}}>Groups allow you to organize your subscribers based on interest, actions, and more.</p>
          <button onClick={()=>{setAddGroupModalFlag(true)}}>Create Group</button>
        </div>
      )}
    </div>
  );
};

export default Group;
