import "./Subscribe.css";
import { MdChatBubble, MdDashboard } from "react-icons/md";
import { GoChevronDown } from "react-icons/go";
import { FaRegBell } from "react-icons/fa6";
import { IoSearchOutline } from "react-icons/io5";
import AllSubscriber from "./pages/AllSubscriber";
import Segment from "./pages/segment/Segment";
import Group from "./pages/group/Group";
import Fields from "./pages/fields/Fields";
import Stats from "./pages/stats/Stats";
import CleanUpInactive from "./pages/cleanUpInactive/CleanUpInactive";
import History from "./pages/history/History";
import { useContext, useState } from "react";
import { MdOutlinePersonOutline, MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logoutHandle } from "../../../redux/features/AuthSlice";
import { useNavigate } from "react-router-dom";
import SearchModal from "../../../modal/searchModal/SearchModal";
import UploadFilerModal from "../../../modal/uploadFileModal/UploadFileModal";
import SubscriberHeader from "../../../components/subscriberHeader/SubscriberHeader";
import SubscriberNavbar from "../../../components/subscriberNavbar/SubscriberNavbar";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import { useLocation } from "react-router-dom";
import { returnDateFromTimestamp, returnTimeFromTimestamp } from "../../../utils/Utils";

const Subsribe = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const ALL_SUBSCRIBER = "ALL_SUBSCRIBER";
  const SEGMENT = "SEGMENT";
  const GROUP = "GROUP";
  const FIELDS = "FIELDS";
  const STATS = "STATS";
  const CLEAN_UP_INACTIVE = "CLEAN_UP_INACTIVE";
  const HISTORY = "HISTORY";
  const [subscribeNavTabItemFlag, setSubscribeNavTabItemFlag] = useState({
    key: "ALL_SUBSCRIBER",
    value: true,
  });

  const [showLogoutModalFlag, setShowLogoutModalFlag] = useState(false);
  const [searchModalFlag, setSearchModallag] = useState(false);
  const [addSubcriberModalFlag, setAddSubscriberModallag] = useState(false);
  const [viewData, setViewData] = useState("");

  const constConfigData = useContext(MyContext);

  console.log("Location_of_subscribe", location);

  const handleChangeNavigation = (value) => {
    let obj1 = { key: value, value: true };
    setSubscribeNavTabItemFlag(obj1);
    if (value === ALL_SUBSCRIBER) {
      navigate("/contacts/all", { state: { fromScreen: ALL_SUBSCRIBER } });
    } else if (value === SEGMENT) {
      navigate("/contacts/segment", { state: { fromScreen: SEGMENT } });
    } else if (value === GROUP) {
      navigate("/contacts/group", { state: { fromScreen: GROUP } });
    } else if (value === HISTORY) {
      navigate("/contacts/history", { state: { fromScreen: HISTORY } });
    }
  };
  const handleAddContacts = () => {
    navigate("/contacts/import/", { state: { fromScreen: ALL_SUBSCRIBER } });
  };
  // const handleLogout = ()=>{
  //   setShowLogoutModalFlag(false)
  //   dispatch(logoutHandle())
  //   localStorage.removeItem("token")
  //   localStorage.removeItem("email")
  //   // localStorage.removeItem("password")

  //   navigate("/")
  // }
  return (
    <div>
      {/* <div className="dashboard-container-right-header">
        <div className="dashboard-container-right-header-left">
          <div
            style={{
              backgroundColor: "#ffffff",
              padding: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MdChatBubble color="#4DC36B" />
          </div>

          <div className="dashboard-container-right-header-left-content">
            <p id="dashboard-container-right-header-left-content-1">
              Suraj Singh
            </p>
            <p id="dashboard-container-right-header-left-content-2">Free Pln</p>
          </div>
          <GoChevronDown />
        </div>
        <div className="dashboard-container-right-header-right">
          <div className="flex-row">
            <MdDashboard />
          </div>
          <div className="flex-row">
            <FaRegBell />
          </div>
          <div className="dashboard-container-right-header-right-content">
            <p id="dashboard-container-right-header-right-content-1">
              Suraj Singh
            </p>
            <p id="dashboard-container-right-header-right-content-2">
              suraj.singh@oodles.io
            </p>
          </div>
          <div className="flex-row-1">
            <p>S</p>
          </div>
          <div style={{position:"relative"}}>
            <GoChevronDown style={{cursor:"pointer"}} onClick={()=>{setShowLogoutModalFlag(!showLogoutModalFlag)}} />
            {
              showLogoutModalFlag && <div className="header-logout">
              <ul style={{padding:"0px",listStyle:"none"}}>
                <li ><MdOutlinePersonOutline style={{marginRight:"5px"}} /> <p>My Profile</p></li>
                <li onClick={()=>{handleLogout()}}><MdLogout style={{marginRight:"5px"}} /> <p>Logout</p></li>
              </ul>
            </div>
            }
            
          </div>
        </div>
      </div> */}
      {/* <SubscriberHeader /> */}

      <div>
      {
        location?.search && <div style={{ height: "30px",display:"flex",color:"#6b6b6b",cursor:"pointer", fontSize:"0.9em" }}>
        <p style={{marginRight:"8px",color:"#4b4b4b"}} onClick={()=>{navigate("/contacts")}}>Contact</p>
        <p style={{marginRight:"8px",}}>{">"}</p>
        <p style={{marginRight:"8px",color:"#4b4b4b"}} onClick={()=>{
          navigate("/contacts/segment")
        }}>{searchParams.get("type")}</p>
        <p style={{marginRight:"8px"}}>{">"}</p>
        <p style={{color:"black"}}>{viewData?.name}</p>
      </div>
      }
      </div>
      {(!location?.search) && <div style={{ height: "30px" }}></div>}
      <div className="subscribe-heading">
        <div>
          <h2
            style={{
              fontWeight: "600",
              fontSize: "2.4em",
              letterSpacing: "0.8px",
            }}
          >
            {location?.search ? viewData?.name : constConfigData?.Contacts}
          </h2>
          <p style={{marginTop:"15px", color:"#656565"}}>
            {
              location?.search && <div>
                Created{" "}
              <abbr  id="group-abbr">
                {returnDateFromTimestamp(viewData?.createdAt)}{" "}
                <span className="group-abbr-span">
                  {returnTimeFromTimestamp(viewData?.createdAt)}
                </span>{" "}
              </abbr>
              </div>
            }
          </p>
        </div>

        {
          (!location?.search) && <div className="subscribe-heading-right">
          {/* <div
            onClick={() => {
              // setAddSubscriberModallag(true)
              // handleAddContacts()
              setSearchModallag(true);
            }}
            className="subscribe-heading-right-1"
          >
            <IoSearchOutline />
            <p
              style={{
                fontWeight: "550",
                fontSize: "14px",
                padding: "0.13rem 0.5rem",
                color: "#424242",
              }}
            >
              Searching
            </p>
          </div> */}

          <div
            onClick={() => {
              // setAddSubscriberModallag(true)
              handleAddContacts();
            }}
            className="subscribe-heading-right-2"
          >
            <p
              style={{
                fontWeight: "550",
                fontSize: "14px",
                padding: "0.13rem 0.5rem",
                color: "white",
              }}
            >
              {constConfigData?.Add_Contacts}
            </p>
          </div>
        </div>
        }
        {searchModalFlag && (
          <SearchModal setSearchModallag={setSearchModallag} />
        )}
        {
          // addSubcriberModalFlag && <UploadFilerModal setAddSubscriberModallag={setAddSubscriberModallag}  />
        }
      </div>
      <SubscriberNavbar />
      {/* <div className="subscriber-tab">
        <ul>
          <li
            onClick={() => {
              handleChangeNavigation(ALL_SUBSCRIBER);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === ALL_SUBSCRIBER
                  ? "#4DC36B"
                  : "#F3F4F6"
              }`,
            }}
          >
            All Subscriber
          </li>
          <li
            onClick={() => {
              handleChangeNavigation(SEGMENT);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === SEGMENT ? "#4DC36B" : "#F3F4F6"
              }`,
            }}
          >
            Segments
          </li>
          <li
            onClick={() => {
              handleChangeNavigation(GROUP);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === GROUP ? "#4DC36B" : "#F3F4F6"
              }`,
            }}
          >
            Group
          </li>
          <li
            onClick={() => {
              handleChangeNavigation(HISTORY);
            }}
            style={{
              borderBottom: `2px solid ${
                subscribeNavTabItemFlag.key === HISTORY ? "#4DC36B" : "#F3F4F6"
              }`,
            }}
          >
            History
          </li>
        </ul>
      </div> */}
      <div style={{ padding: "40px 0px" }}>
        {(() => {
          if (subscribeNavTabItemFlag.key === ALL_SUBSCRIBER) {
            return (
              <>
                <AllSubscriber setViewData={setViewData} />
              </>
            );
          } else if (subscribeNavTabItemFlag.key === SEGMENT) {
            return <Segment />;
          } else if (subscribeNavTabItemFlag.key === GROUP) {
            return <Group />;
          } else if (subscribeNavTabItemFlag.key === HISTORY) {
            return <History />;
          }
        })()}
      </div>
    </div>
  );
};

export default Subsribe;
