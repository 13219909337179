import "./Syncfuse.css";
import React from "react";
import { MyContext } from "../../ContextAPIStore/CreateContext";
import { useContext } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { DataManager, Query, UrlAdaptor } from "@syncfusion/ej2-data";
import { useEffect } from "react";
import { contactColumnData } from "../../asset/documentfiles/Documents";
import { exportColumnPostAPI, updateExportColumnPostAPI } from "../../services/servicesAPI";
import { getToken } from "../../utils/getToken";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { returnDateFromTimestamp } from "../../utils/Utils"

const Syncfuse = ({ selectedRow, setSelectedRow }) => {
  const constConfigData = useContext(MyContext);
  const [selectedRowIndices, setSelectedRowIndices] = useState({ key: "" });
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const [SyncFuseData, setSyncFuseData] = useState();
  const [payloadData, setPayloadData] = useState({ columns: [] });
  const [debouncedCheckboxes, setDebouncedCheckboxes] = useState(payloadData);
  const [viewAll, setViewAll] = useState(false);
  const [togData, setTogData] = useState([
    { name: "Email", id: "66262ad4d1efcaf6e2b3298a" },
    { name: "Phone", id: "66262ad4d1efcaf6e2b3298c" },
    { name: "Street address", id: "66262ad4d1efcaf6e2b3298e" },
    { name: "City", id: "66262ad4d1efcaf6e2b32990" },
    { name: "State", id: "66262ad4d1efcaf6e2b32992" },
    { name: "Zip", id: "66262ad4d1efcaf6e2b32994" },
  ]);
  const [allData, setAllData] = useState([])
  const [check, setCheck] = useState([]);

  // console.log("===========================",isChecked)
  // console.log("check",check)

  useEffect(() => {
    console.log(
      "process.env.REACT_APP_BASE_URL",
      process.env.REACT_APP_BASE_URL
    );
    SyncFun();
  }, []);
  

  const url = viewAll
    ? `${process.env.REACT_APP_BASE_URL}/contact/getExportColumns?$skip=0&$top=100`
    : `${process.env.REACT_APP_BASE_URL}/contact/getExportColumns?$skip=0&$top=100`;

  /* const SyncFun = async () => {
    const SyncData = new DataManager({
      adaptor: new UrlAdaptor(),
      url: url,
      headers: [
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      ],
    });

    setSyncFuseData(SyncData);
  }; */

  const exportColumnPostAPIFun = async ()=>{
    const response = await exportColumnPostAPI()
    if(response.success){
      console.log(response)
      setAllData(response.data.result)
    }
  }

  useEffect(()=>{
    exportColumnPostAPIFun()
  },[debouncedCheckboxes])

  const SyncFun = async () => {
    const SyncData = new DataManager({
      adaptor: new UrlAdaptor(),
      url: url,
      headers: [
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      ],
    });

    const result = await SyncData.executeQuery(new Query())
    console.log(result.result)
    setSyncFuseData(result.result)
  };

  const [showAllRows, setShowAllRows] = useState(false);
  const handleViewMore = () => {
    setShowAllRows((prevShowAllRows) => !prevShowAllRows);
  };

  

  const FilterOptions = {
    type: "CheckBox",
    enableInfiniteScrolling: true,
  };

  /* const FilterOptions = { columns: [
    { field: 'tableName', operator: 'greaterthan', value: 2 }
] }; */
  
  const selectionSettings = { mode: "Row", type: "Multiple" };

  const handleRowSelected = (args) => {
    console.log("args as payload ", args);
    const updatetExportColumnAPI = async () => {
      try {
        const res = await updateExportColumnPostAPI(payloadData);
        console.log("UpdateExportColumnRes", res);
        setCheckBoxValues([]);
        SyncFun();
      } catch (err) {
        console.log("UpdateExportColumnErr", err);
      }
    };
    updatetExportColumnAPI();
  };

  const handleCheckbox = (ev, props) => {
    if (ev.checked == true) {
      if (props?._id == "66262ad4d1efcaf6e2b3298a") {
        console.log("email wala");
        setCheckBoxValues((prev) => [
          ...prev,
          {
            columnId: "66262ad4d1efcaf6e2b3298c",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b3298e",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b32990",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b32992",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b32994",
            canExport: false,
          },
        ]);
      }
      if (props?._id == "66262ad4d1efcaf6e2b3298c") {
        console.log("phone wlaa ");
        setCheckBoxValues((prev) => [
          ...prev,
          {
            columnId: "66262ad4d1efcaf6e2b3298a",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b3298e",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b32990",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b32992",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b32994",
            canExport: false,
          },
        ]);
      }
      if (
        props?._id == "66262ad4d1efcaf6e2b3298e" ||
        props?._id == "66262ad4d1efcaf6e2b32990" ||
        props?._id == "66262ad4d1efcaf6e2b32992" ||
        props?._id == "66262ad4d1efcaf6e2b32994"
      ) {
        console.log("address wla ");
        setCheckBoxValues((prev) => [
          ...prev,
          {
            columnId: "66262ad4d1efcaf6e2b3298a",
            canExport: false,
          },
          {
            columnId: "66262ad4d1efcaf6e2b3298c",
            canExport: false,
          },
        ]);
      }
    }
    setCheckBoxValues((prev) =>
      prev
        ? [
            ...prev,
            {
              columnId: props._id,
              canExport: ev.checked,
            },
          ]
        : {
            columnId: props._id,
            canExport: ev.checked,
          }
    );
  };

  const filterDuplicates = (arr) => {
    const uniqueMap = {};
    arr.forEach((item) => {
      const key = `${item.columnId}`;
      uniqueMap[key] = item;
    });
    return Object.values(uniqueMap); // Convert map obj values back to array
  };

  useEffect(() => {
    const filteredData = filterDuplicates(checkBoxValues);
    setPayloadData({ columns: filteredData });
  }, [checkBoxValues]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedCheckboxes(payloadData);
    }, 2000); // Debounce delay of 500 milliseconds

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [payloadData]);

  useEffect(() => {
    if (payloadData.columns.length > 0) {
      handleRowSelected(payloadData);
    }
  }, [debouncedCheckboxes]);

  const template = (props) => {
    return (
      <div style={{background:"", width:""}}>
        {props.isDisabled ? (
          <input
            className="checkboxCustom"
            style={{
              backgroundColor: "#a8a8a8",
              border: "1px solid #a8a8a8",
            }}
            checked={true}
            type="checkbox"
          ></input>
        ) : (
          <CheckBoxComponent
            className={
              props.columnName == "Email" ||
              props.columnName == "Phone" ||
              props.columnName == "Street address" ||
              props.columnName == "City" ||
              props.columnName == "State" ||
              props.columnName == "Zip"
                ? "checkbox1"
                : "checkbox2"
            }
            style={{
              border:
                props.columnName == "Email" ||
                props.columnName == "Phone" ||
                props.columnName == "Street address" ||
                props.columnName == "City" ||
                props.columnName == "State" ||
                props.columnName == "Zip"
                  ? "1px solid orange"
                  : " 1px solid blue",
            }}
            checked={props.canExport}
            change={(event) => {
              handleCheckbox(event, props);
            }}
            label=""
          />
        )}
      </div>
    );
  };

  const exportHeaderTemplet = () => {
    return (
      <div style={{ fontSize: "1.1em", fontWeight: "400" ,background:"", width:""}}>
        {/* {constConfigData?.Export} */}
        Export
      </div>
    );
  };
  const nametHeaderTemplet = () => {
    return (
      <div
        style={{
          fontSize: "1.1em",
          fontWeight: "400",
          backgroundColor: "tranparent",
          paddingLeft: "6px",
        }}
      >
        {/* {constConfigData?.Export} */}
        Name
      </div>
    );
  };
  const tableNametHeaderTemplet = () => {
    return (
      <div
        style={{
          fontSize: "1.1em",
          fontWeight: "400",
          backgroundColor: "tranparent",
          // paddingLeft: "6px",
        }}
      >
        {/* {constConfigData?.Export} */}
        Table Name
      </div>
    );
  };
  const canExporttHeaderTemplet = () => {
    return (
      <div
        style={{
          fontSize: "1.1em",
          fontWeight: "400",
          backgroundColor: "tranparent",
          paddingLeft: "6px",
        }}
      >
        {/* {constConfigData?.Export} */}
        Can Export
      </div>
    );
  };
  const canEditHeaderTemplet = () => {
    return (
      <div
        style={{
          fontSize: "1.1em",
          fontWeight: "400",
          backgroundColor: "tranparent",
          paddingLeft: "6px",
        }}
      >
        {/* {constConfigData?.Export} */}
        Can edit
      </div>
    );
  };
  const canViewHeaderTemplet = () => {
    return (
      <div
        style={{
          fontSize: "1.1em",
          fontWeight: "400",
          backgroundColor: "tranparent",
          paddingLeft: "6px",
        }}
      >
        {/* {constConfigData?.Export} */}
        Can view
      </div>
    );
  };
  const createDateHeaderTemplet = () => {
    return (
      <div
        style={{
          fontSize: "1.1em",
          fontWeight: "400",
          backgroundColor: "tranparent",
          paddingLeft: "10px",
        }}
      >
        {/* {constConfigData?.Export} */}
        Create Date
      </div>
    );
  };
  useEffect(() => {}, []);

  const viewAllFunction = () => {
    return viewAll;
  };
  const columnCount = 6
  const commonColumnWidth = `${100 / 10}%`;

  const handleFilterSelection = (args) => {
    // Access the filter data from args
    const filterData = args.filterCollection;

    console.log("FilterData",filterData)
    
    // Your custom logic here to generate the custom payload
    const customPayload = {
        // Add your custom data here
    };

    // Update state with the custom payload
    this.setState({ customPayload });

    // You can perform further actions here based on the custom payload
}

  return (
    <div className="export-columns">
      <div className="export-columns-heading">
        <h2>{constConfigData?.Contact_Columns}</h2>
      </div>
      <div style={{ height: "40px" }}></div>

      <div style={{ height: "30vh" }} className="export-columns-content">
        <GridComponent
          className="fuse"
          height={viewAllFunction() ? "auto" : 406}
          selectedRowIndex={1}
          filterSettings={FilterOptions}
          allowFiltering={true}
          allowSorting={true}
          selectionSettings={selectionSettings}
          dataSource={allData}
          statelessTemplates={["directiveTemplates"]}
          filterSelection={handleFilterSelection}
          style={{
            borderLeft: "none",
            borderRight: "none",
            cursor: "pointer",
            zIndex: "0",
          }}
        >
          <ColumnsDirective>
            <ColumnDirective 
              headerTemplate={exportHeaderTemplet}
              template={template}
              width={commonColumnWidth}
            ></ColumnDirective>
            <ColumnDirective
              headerTemplate={nametHeaderTemplet}
              width={`${100 / 8}%`}
              textAlign="left"
              template={(props) => {
                /* if(props?.columnName == "Email" || props?.columnName == "Phone" || props?.columnName == "Street address" || props?.columnName == "City" || props?.columnName == "State" || props?.columnName == "Zip"){
                  setCheck((prev) => ([...prev, props]))
                } */
                return (
                  <span style={{ color: "rgb(76,76,76)", fontWeight: "400" }}>
                    {props.columnName}
                  </span>
                );
              }}
            />
            <ColumnDirective
              field="tableName"
              headerTemplate={tableNametHeaderTemplet}
              width={`${100 / 7}%`}
              textAlign="left"
              template={(props) => {
                return (
                  <span style={{ color: "rgb(76,76,76)", fontWeight: "400" }}>
                    {props.tableName}
                  </span>
                );
              }}
            />
            <ColumnDirective
              // field="canExport"
              headerTemplate={canExporttHeaderTemplet}
              width={`${100 / 10}%`}
              textAlign="left"
              allowSorting={false}
              template={(props) => {
                return (
                  <span style={{ color: "rgb(76,76,76)", fontWeight: "400" }}>
                    {props.isActive ? "Yes" : "No"}
                  </span>
                );
              }}
            />
            <ColumnDirective
              // field="canExport"
              headerTemplate={canEditHeaderTemplet}
              width={`${100 / 10}%`}
              textAlign="left"
              allowSorting={false}
              template={(props) => {
                return (
                  <span style={{ color: "rgb(76,76,76)", fontWeight: "400" }}>
                    {props.canEdit ? "Yes" : "No"}
                  </span>
                );
              }}
            />
            <ColumnDirective
              // field="canExport"
              headerTemplate={canViewHeaderTemplet}
              width={`${100 / 10}%`}
              textAlign="left"
              allowSorting={false}
              template={(props) => {
                return (
                  <span style={{ color: "rgb(76,76,76)", fontWeight: "400" }}>
                    {props.canView ? "Yes" : "No"}
                  </span>
                );
              }}
            />
            <ColumnDirective
              // field="createDate"
              headerTemplate={createDateHeaderTemplet}
              width={`${100 / 6}%`}
              textAlign="left"
              allowSorting={false}
              template={(props) => {
                return (
                  <span style={{ color: "rgb(76,76,76)", fontWeight: "400" }}>
                    {returnDateFromTimestamp(props?.createdAt)}
                  </span>
                );
              }}
            />
          </ColumnsDirective>
          <Inject services={[Filter, Page, Sort]} />
        </GridComponent>

        <div className="outer-view" style={{ paddingBottom: "3rem" }}>
          <div
            className="view-more"
            onClick={() => {
              handleViewMore();
              setViewAll(!viewAll);
            }}
          >
            {showAllRows ? (
              <>
                <p>{`${constConfigData?.View_All}`}</p>{" "}
                <MdKeyboardArrowUp
                  style={{ fontSize: "1.5em", fontWeight: "400" }}
                />
              </>
            ) : (
              <div
                onClick={() => {
                  setViewAll(!viewAll);
                }}
                style={{ display: "flex", flexDirection: "row" }}
              >
                {" "}
                <p>{`${constConfigData?.View_All}`}</p>{" "}
                <MdKeyboardArrowDown
                  style={{ fontSize: "1.5em", fontWeight: "400" }}
                />
              </div>
            )}
            {/* <p>{showAllRows ? `${constConfigData?.View_Less}` :  `${constConfigData?.View_All}`}</p>
            <MdKeyboardArrowDown style={{fontSize:'1.5em',fontWeight:'400'}}/> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Syncfuse;
