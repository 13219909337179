import React, { useContext, useEffect, useState } from "react";
import "./Backup.css";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useSearchParams } from "react-router-dom";
import { MyContext } from "../../../ContextAPIStore/CreateContext";
import { array } from "yup";
import BackupSVG from "../../../asset/svg/BackupSVG";
import {
  activityGetAPI,
  backupGetAPI,
  restoreGetAPI,
} from "../../../services/servicesAPI";
import {
  formatDateInUs,
  formatDateTime,
  returnDateAndTimeFromTimestamp,
  returnDateFromTimestamp,
} from "../../../utils/Utils";
import LoaderComponent from "../../../components/loaderComponent/LoaderComponent";
import CrossSVG from "../../../asset/svg/CrossSVG";
import { toast } from "react-toastify";

function Backup() {
  const [isAll, setIsAll] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [isAllMonthly, setIsAllMonthly] = useState(true);
  const [daily, setDaily] = useState([]);
  const [backupData, setBackupData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [filterSortingPayloadGroup, setFilterSortingPayloadGroup] = useState({
    $skip: 0,
    $top: 10,
  });
  const [pagingData, setPagingData] = useState({ page: 1, limit: 10 });
  const [pagging, setPagging] = useState({});
  const [totalCount, setTotalCount] = useState();
  const [loader, setLoader] = useState(false);
  const [restoreBool, setRestoreBool] = useState(false);
  const [selected, setSelected] = useState();
  const [checked, setChecked] = useState(false);

  const BackupGetAPIFun = async () => {
    setLoader(true);
    const response = await backupGetAPI();

    if (response.success) {
      setBackupData(response.data.data);
    }

    setLoader(false);
  };

  useEffect(() => {
    BackupGetAPIFun();
  }, []);

  const activityGetAPIFun = async () => {
    setLoader(true);
    const response2 = await activityGetAPI(filterSortingPayloadGroup);
    if (response2.success) {
      console.log(response2);
      setActivityData(response2.data);
      setPagging(response2?.data);
      setTotalCount(response2?.data?.count);
    }
    setLoader(false);
  };

  useEffect(() => {
    activityGetAPIFun();
  }, []);

  const constConfigData = useContext(MyContext);

  let Daily = [];

  let Daily2 = [
    "Date",
    "April 4, 2024 2:00 PM",
    "April 3, 2024 2:00 PM",
    "April 1, 2024 2:00 PM",
  ];

  let Activity = [
    "Activity",
    "All is well All is well.",
    "All is not well.",
    "Restore data of january.",
  ];

  const handleToogle1 = (index) => {
    // setIsAll(!isAll);
    setSelectedIndex(index)
  };

  const handleToogle2 = () => {
    setIsAllMonthly(!isAllMonthly);
  };

  const handlePagingApiData = async (value) => {
    console.log("value======>", value);
    console.log("pagingData:", pagingData);
    console.log("pagging:", pagging);

    if (value === 1) {
      if (pagingData.page >= 2) {
        let page = pagingData.page - 1
        let limit = pagingData.limit
        let payload = {page:page,limit:limit}
        console.log(pagingData, pagging)
        console.log("payload",(pagging?.page-1) * limit)
        filterSortingPayloadGroup.$skip=(pagging?.page-2) * limit;
        filterSortingPayloadGroup.$top=limit
        setPagingData(payload)
        await activityGetAPIFun(filterSortingPayloadGroup);
      }
    } else {
      if (totalCount > 10 * pagingData.page) {
        let page = pagingData.page + 1;
        let limit = pagingData.limit;
        let payload = { page: page, limit: limit };
        filterSortingPayloadGroup.$skip = limit * pagging?.page;
        filterSortingPayloadGroup.$top = limit;
        setPagingData(payload);
        await activityGetAPIFun(filterSortingPayloadGroup);
      }
    }
  };

  const handleRestore = () => {
    setRestoreBool(true);
  };

  const onclose = () => {
    setRestoreBool(!restoreBool);
  };

  const restoreGetAPIfun = async (payload, checked) => {
    const response = await restoreGetAPI(payload, checked);
    if (response.success) {
      toast.success(response.message);
      setLoader(false);
    } else {
      toast.error(response.message);
    }
    setChecked(false);
  };

  const onSuccess = () => {
    restoreGetAPIfun(selected?._id, checked);
  };

  return (
    <div>
      {restoreBool && (
        <div className="generalconfirmmodal">
          <div className="generalconfirmmodal-content">
            <div
              className="generalconfirmmodal-content-clos"
              onClick={() => {
                onclose();
              }}
            >
              <div>
                <CrossSVG />
              </div>
            </div>
            <div className="generalconfirmmodal-content-content">
              <div className="generalconfirmmodal-content-content-1">
                <h3 style={{ textAlign: "center" }}>
                  Restoring {formatDateInUs(selected?.backupDate)} Backup
                </h3>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
                className="generalconfirmmodal-content-content-2"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                    type="checkbox"
                  ></input>
                </div>
                <p style={{ textAlign: "center", paddingLeft: "1rem" }}>
                  Make a backup for today first
                </p>
              </div>
              <div className="generalconfirmmodal-content-content-3">
                <button
                  onClick={() => {
                    onclose();
                  }}
                  style={{
                    color: "#404040",
                    backgroundColor: "#E5E7EB",
                    padding: "0.7rem 2rem",
                  }}
                >
                  No
                </button>
                <button
                  onClick={() => {
                    onSuccess();
                    onclose();
                  }}
                  style={{
                    backgroundColor: "#2081E3",
                    color: "#ffffff",
                    padding: "0.5rem 2rem",
                  }}
                >
                  {"Yes"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div>
          {
            backupData.length ? backupData.map((data, index) => <>
              <div className="top">
            {selectedIndex === index ? (
              <GoChevronDown
                style={{ cursor: "pointer", marginRight: "13px" }}
                onClick={() => {
                  handleToogle1(index);
                }}
                size={15}
              />
            ) : (
              <GoChevronUp
                style={{ cursor: "pointer", marginRight: "13px" }}
                onClick={() => {
                  handleToogle1(index);
                }}
                size={15}
              />
            )}
            <h3 style={{ color: "#505050", textTransform: 'capitalize' }}>
              {data?.type} {constConfigData?.Backup}
            </h3>
          </div>
          <div className="maped">
            {selectedIndex === index && (
              <div className={`${data?.list?.length ? 'backup-wrapper' : ''}`}>
                {data?.list.map((e) => {
                  return (
                    <>
                      <div className="top1">
                        <span
                          onClick={() => {
                            handleRestore(e);
                            setSelected(e);
                          }}
                          style={{ marginRight: "1rem" }}
                        >
                          <BackupSVG />
                        </span>
                        {/* {formatDateInUs(e?.backupDate)} */}
                        {formatDateTime(e?.backupDate)}
                      </div>
                    </>
                  );
                })}
              </div>
            )}
          </div>
            </>) : null }
        </div>

        {/* <div>
          <div style={{ marginTop: "20px" }} className="top">
            {isAllMonthly ? (
              <GoChevronDown
                style={{ cursor: "pointer", marginRight: "13px" }}
                onClick={() => {
                  handleToogle2();
                }}
                size={15}
              />
            ) : (
              <GoChevronUp
                style={{ cursor: "pointer", marginRight: "13px" }}
                onClick={() => {
                  handleToogle2();
                }}
                size={15}
              />
            )}
            <h3 style={{ color: "#505050" }}>
              Monthly {constConfigData?.Backup}
            </h3>
          </div>
          <div className="maped">
            {isAllMonthly && Daily?.length ? (
              <div className="backup-wrapper">
                {Daily.map((e) => {
                  return (
                    <>
                      <div className="top1">
                        <span
                          onClick={() => {
                            handleRestore(e);
                            setSelected(e);
                          }}
                          style={{ marginRight: "1rem" }}
                        >
                          <BackupSVG />
                        </span>
                        {e}
                      </div>
                    </>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div> */}

        <div>
          {loader && <LoaderComponent />}
          <div style={{ marginTop: "25px" }} className="top">
            <h3 style={{ color: "#505050" }}>Activity</h3>
          </div>
          <div className="maped1">
            <div className="left-column">
              {true &&
                activityData?.result?.map((e) => (
                  <div className={e === "Date" ? "topHead" : "topMany"} key={e}>
                    {formatDateTime(e?.createdAt)}
                  </div>
                ))}
            </div>
            <div className="right-column">
              {true &&
                activityData?.result?.map((e) => (
                  <div
                    className={e === "Activity" ? "topHead" : "topMany"}
                    key={e}
                  >
                    {e?.activityName} , {e?.description}
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className="btnContainer">
              <div className="group-content-3-2">
                <button
                  disabled={activityData?.prev ? false : true}
                  onClick={() => {
                    handlePagingApiData(1);
                  }}
                  style={{
                    backgroundColor: activityData?.prev ? "#E5E7EB" : "#F2F3F5",
                    color: activityData?.prev ? "#404040" : "rgb(159,159,159)",
                    cursor: activityData?.prev ? "pointer" : "",
                    padding: "12px 22px",
                    fontSize: "1.2em",
                  }}
                >
                  {constConfigData?.Previous}
                </button>
                <button
                  disabled={activityData?.next ? false : true}
                  onClick={() => {
                    handlePagingApiData(2);
                  }}
                  style={{
                    backgroundColor: activityData?.next ? "#E5E7EB" : "#F2F3F5",
                    color: activityData?.next ? "#404040" : "rgb(159,159,159)",
                    cursor: activityData?.next ? "pointer" : "",
                    padding: "12px 22px",
                    fontSize: "1.2em",
                  }}
                >
                  {constConfigData?.Next}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Backup;
