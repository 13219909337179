import { Outlet } from "react-router-dom";
import "./Setting.css";
import SettingNavbar from "./settingNavbar.js/SettingNavbar";
import { MyContext } from "../../ContextAPIStore/CreateContext";
import { useContext } from "react";
const ConstantConfig = () => {
  const constConfigData = useContext(MyContext);
  return (
    <div>
      <div style={{ height: "35px" }}></div>
      <div className="setting-container">
        <div className="setting-container-heading">
          <h2
            style={{
              fontWeight: "600",
              fontSize: "2.4em",
              letterSpacing: "0.8px",
            }}
          >
            {constConfigData?.Settings}
          </h2>
        </div>
        <div style={{ height: "30px" }}></div>
        <div>
          <SettingNavbar />
        </div>
        <div style={{ height: "35px" }}></div>

        <Outlet />
      </div>
    </div>
  );
};

export default ConstantConfig;
