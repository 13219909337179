import { useContext, useEffect, useRef, useState } from "react";
import "./AllSubscriber.css";
import { GoChevronDown } from "react-icons/go";
import {
  actionList,
  activeList,
  data,
  searchFilterList,
  toogleColumnsItemList,
} from "../../../../asset/documentfiles/Documents";
import { IoAddCircleOutline } from "react-icons/io5";
import {
  MdOutlineRemoveCircleOutline,
  MdDeleteOutline,
  MdOutlineFileDownload,
} from "react-icons/md";
import { toast } from "react-toastify";
import { PiProhibitInsetLight } from "react-icons/pi";
import {
  convertNumberIntoUSFromat,
  handleIsCheckedOddOrEven,
} from "../../../../utils/Utils";

import FilterParent from "../../../../components/filterNewComp/filterParent";
import { MyContext } from "../../../../ContextAPIStore/CreateContext";
import {
  filterCountPostAPI,
  getAllContactExportCsvAPI,
  getAllContactsPostAPI,
  getContactExportCsvAPI,
  getExportColumnsAPI,
  getGroupByIdPostAPI,
  getViewByIdPostAPI,
  postDeleteFromAllGroupInContactPageAPI,
} from "../../../../services/servicesAPI";
import { useLocation, useNavigate } from "react-router-dom";
import AddModal from "../../../../modal/addGroupModal/AddModal";
import GeneralConfirmModal from "../../../../modal/generalConfirmModal/GeneralConfirmModal";
import RemoveModal from "../../../../modal/removeModal/RemoveModal";
import FileSVG from "../../../../asset/svg/FileSVG";
import DownloadSVG from "../../../../asset/svg/DownloadSVG";
import SmallLoaderComponent from "../../../../components/smallLoaderComponent/SmallLoaderComponent";

const AllSubscriber = ({ setViewData }) => {
  const controllerRef = useRef();
  const [showAction, setShowAction] = useState(false);
  const [showToggleColumns, setShowToggleColumns] = useState(false);
  const [toggleColumnsItem, setToggleColumnsItem] = useState([]);
  const [allToggleColumnsItem, setAllToggleColumnsItem] = useState([]);

  const [arrayFilterList, setArrayFilterList] = useState([]);
  const [filterArray, setFilterArray] = useState([]);

  const [snippetCount, setSnippetCount] = useState([0]);

  const [activeFilterFlag, setActiveFilterFlag] = useState(false);
  const [selectedActiveFilterItem, setSelectedActiveFilterItem] = useState("");

  const [tableDataInitialList, setTableInitialDataList] = useState([]);
  const [tableDataFinalList, setTableFinalDataList] = useState([]);

  const [addSnippetFlag, setAddSnippetFlag] = useState(false);
  const [addSnippetOrFlag, setAddSnippetOrFlag] = useState(false);

  const [selectedSnippetData, setSelectedSnippetData] = useState([]);
  const [idxNo, setIdxNo] = useState([]);
  const [actionId, setActionId] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const constConfigData = useContext(MyContext);
  const [selectAllCount, setSelectAllCount] = useState(false);
  const [onSelectVisibleFlag, setonSelectVisibleFlag] = useState(false);
  const [filterCountPayload, setFilterCountPayload] = useState();

  const [snippetData, setSnippetData] = useState([
    { id: 0, and: [{ ied: 0 }], andStatus: false },
  ]);

  const [refreshPage, setRefreshPage] = useState(false);
  const [allFilterData, setAllFilterData] = useState([]);
  const [find, setFind] = useState();
  const [aid, setAid] = useState(0);
  const [contactsAllData, setContactsAllData] = useState([]);
  const [pagingData, setPagingData] = useState({ skip: 0, page: 1, limit: 25 });
  const [payload, setPayload] = useState({ isExpanded: true });
  const [totalCount, setTotalCount] = useState();
  const [totalPages, setTotalPages] = useState();
  const [exportAllData, setExportAllData] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [selectedOption, setSelectedOption] = useState("Expanded");
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAllId, setSelectAllId] = useState([]);
  const [squareIcon, setSquareIcon] = useState(false);
  const [newCheckBox, setNewCheckBox] = useState(false);
  const [filterCount, setFilterCount] = useState([]);

  const [state, setState] = useState({
    contactIds: [],
  });
  const [chooseAFilterArrayDataPayload, setChooseAFilterArrayDataPayload] =
    useState([]);
  const [hasPrevNextPage, setHasPrevNextPage] = useState({});
  const [smallLoaderFlag, setSmallLoaderFlag] = useState(false);

  let option = ["Expanded", "Grouped"];
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  console.log("myParam==============>", params.get("id"));

  const filterCountAPIFun = async (payload) => {
    setFilterCount(false);
    const response = await filterCountPostAPI(payload);

    if (response.success) {
      setFilterCount(response.data.data);
      console.log("RESPonse of countFilter", response);
    }
  };

  const allClick = () => {
    filterCountAPIFun(filterCountPayload);
  };

  let tableColumnHead = [];
  const toggleDropdown = (key) => {
    // console.log("column index", key);
    setDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle dropdown state for the specified key
    }));
  };
  // let checkBoxArray =[]
  const handleCheckboxChange = (ind, check, id) => {
    if (check) {
      // setSquareIcon(true)

      contactsAllData[ind].isCheck = false;
      const index = selectedIds.indexOf(id);
      if (index !== -1) {
        const newSelectedIds = [...selectedIds];
        newSelectedIds.splice(index, 1);
        setSelectedIds(newSelectedIds);
      }
      setState((prevState) => {
        const index = prevState.contactIds.indexOf(id);
        if (index !== -1) {
          const newContactIds = [...prevState.contactIds];
          newContactIds.splice(index, 1);
          return {
            ...prevState,
            contactIds: newContactIds,
          };
        }
        // If id is not found in contactIds array, return the previous state
        return prevState;
      });
    } else {
      // setSquareIcon(false)
      contactsAllData[ind].isCheck = true;
      setSelectedIds([...selectedIds, id]); // Add ID to selectedIds array
      setState((prevState) => ({
        ...prevState,
        contactIds: [...prevState.contactIds, id],
      }));
    }
  };

  // Function to select all checkboxes
  const selectAllCheckboxes = () => {
    const allIds = contactsAllData.map((item) => {
      // console.log("item", item);
      item.isCheck = true;
      return item?._id;
    });
    // console.log("selected ids ", allIds);
    setSelectedIds(allIds);
    setState((prevState) => ({
      ...prevState,
      contactIds: allIds,
    }));
  };
  // console.log('All ids ',selectAllId)
  const date = new Date();
  // console.log("All Data All Data", snippetData);

  useEffect(() => {
    setAllToggleColumnsItem(toogleColumnsItemList);
    setArrayFilterList(searchFilterList);
    setFilterArray(searchFilterList);
    setTableInitialDataList(data);
    setTableFinalDataList(data);
  }, []);

  useEffect(() => {}, [snippetData]);

  const exportColumnsGet = async () => {
    const response = await getExportColumnsAPI();

    // console.log("response get export column",response?.data)

    if (response.success) {
      setExportAllData(response?.data?.result);
      // console.log('Export col data',exportAllData)
    } else {
      toast.error(response.message);
    }
  };
  useEffect(() => {
    exportColumnsGet();
  }, []);

  const contactExportCsvGet = async (payload) => {
    // console.log("payload",payload)
    const response = await getContactExportCsvAPI(payload);
    // console.log("Response============> contacts",response?.data)

    if (response.success) {
      toast.success(response.message);
      const csvData = response.data;
      handleDownload(csvData);
    } else {
      toast.error(response.message);
    }
  };
  const contactAllExportCsvGet = async (payload) => {
    // console.log("Filter payload========>",payload)
    const response = await getAllContactExportCsvAPI({ filters: payload });

    if (response.success) {
      // const csvData = response.data;
      // handleDownload(csvData);
      toast.success(response.message);
      contactsAllData.map((item) => {
        // console.log("item", item);
        item.isCheck = false;
        // return  item?._id
      });
      setonSelectVisibleFlag(false);
      setRefreshPage(!refreshPage);
    } else {
      toast.error(response.message);
    }
  };

  const handleDownload = (csvData) => {
    const csvContent =
      "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
    // console.log("csvcontent",csvContent)
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    setSelectedIds([]);
    contactsAllData.map((item) => {
      // console.log("item", item);
      item.isCheck = false;
      // return  item?._id
    });
  };

  const contactSelectAllExport = () => {
    setSelectAllCount(true);
    setSelectedIds([]);
    contactsAllData.map((item) => {
      item.isCheck = true;
    });
  };

  const changePayload = async (val) => {
    // console.log("selected option value", val);
    if (val === "Grouped") {
      // console.log("hie from group");
      setPayload({ isExpanded: false });
      //  await contactsAllGet(pagingData)
    } else {
      // console.log("hie from expaned");
      setPayload({ isExpanded: true });
      //  await contactsAllGet(pagingData)
    }
  };
  const contactsAllGet = async (
    pagingData,
    chooseAFilteredData,
    selectedActiveFilterItem,
    next
  ) => {
    let temp = {
      isExpanded: payload.isExpanded,
      filters: chooseAFilteredData,
    };

    setFilterCountPayload(temp);
    setSmallLoaderFlag(true);

    const response = await getAllContactsPostAPI(
      pagingData,
      temp,
      selectedActiveFilterItem,
      controllerRef.current
    );
    console.log("response all contacts data============>", response);

    if (response.success) {
      let temp = response?.data?.result.map((item) => {
        item.isCheck = false;

        return item;
      });
      if (next == "false") {
        setTotalCount(response?.data?.count);
      }
      setContactsAllData(temp);
      setTotalPages(response?.data?.totalPages);
      let prevNextPayload = {
        hasPrevPage: response?.data?.hasPrevPage,
        hasNextPage: response?.data?.hasNextPage,
      };
      setHasPrevNextPage(prevNextPayload);
    } else {
      if(response.message !== 'REQUEST_CANCEL') {
        toast.error(response.message);
      }
    }
    setSmallLoaderFlag(false);
  };

  const callApiWithFilter = () => {
    // debugger
    // contactsAllGet(
    //   pagingData,
    //   chooseAFilterArrayDataPayload,
    //   selectedActiveFilterItem,
    //   "false"
    // );
  }

  useEffect(() => {
    // const isFilterPreSelected = params.get('type') === 'dashboard';
    // if(!isFilterPreSelected) {
      contactsAllGet(
        pagingData,
        chooseAFilterArrayDataPayload,
        selectedActiveFilterItem,
        "false"
      );
    // }
  }, [payload, chooseAFilterArrayDataPayload, selectedActiveFilterItem]);

  const handlePagingApiData = async (value) => {
    // console.log("value======>", value);
    if (value === 1) {
      if (pagingData.page >= 2) {
        let page = pagingData.page - 1;
        let skip = pagingData.skip - 25;
        let limit = pagingData.limit;
        let payload = { skip: skip, page: page, limit: limit };
        setPagingData(payload);
        await contactsAllGet(
          payload,
          chooseAFilterArrayDataPayload,
          selectedActiveFilterItem,
          "true"
        );
      }
    } else {
      if (totalCount > 10 * pagingData.page) {
        let page = pagingData.page + 1;
        let skip = pagingData.skip + 25;
        let limit = pagingData.limit;
        let payload = { skip: skip, page: page, limit: limit };
        setPagingData(payload);
        await contactsAllGet(
          payload,
          chooseAFilterArrayDataPayload,
          selectedActiveFilterItem,
          "true"
        );
      }
    }
  };

  const handleClearFilterData = () => {
    // console.log("clicked==========>");
    setAddSnippetOrFlag(false);
    setSnippetData([{ id: 0, and: [{ ied: 0 }], andStatus: false }]);
  };

  const handleActiveList = (value) => {
    setSelectedActiveFilterItem(value);
    setActiveFilterFlag(false);
  };

  const handleActionIcon = (iconValue) => {
    if (iconValue.toUpperCase() === "IOADDCIRCLEOUTLINE".toUpperCase()) {
      return <IoAddCircleOutline style={{ marginRight: "3px" }} />;
    } else if (
      iconValue.toUpperCase() === "MdOutlineRemoveCircleOutline".toUpperCase()
    ) {
      return <MdOutlineRemoveCircleOutline style={{ marginRight: "3px" }} />;
    } else if (
      iconValue.toUpperCase() === "PiProhibitInsetLight".toUpperCase()
    ) {
      return <PiProhibitInsetLight style={{ marginRight: "3px" }} />;
    } else if (iconValue.toUpperCase() === "MdDeleteOutline".toUpperCase()) {
      return <MdDeleteOutline style={{ marginRight: "3px" }} />;
    } else if (
      iconValue.toUpperCase() === "MdOutlineFileDownload".toUpperCase()
    ) {
      return <MdOutlineFileDownload style={{ marginRight: "3px" }} />;
    }
    return;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      let target = event.target;
      let isInsideDropdown =
        target.closest(".select-visible-dropdown") ||
        target.closest(".allsubscriber-content-action-dp-hide") ||
        target.closest(".IsExpanedBased");
      if (!isInsideDropdown) {
        setIsCheck(false);
        setShowAction(false);
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const convertFilterCountReadable = (key) => {
    switch (key) {
      case "bounced":
        return "Bounced";
      case "goodEmails":
        return "Good Emails";
      case "badEmails":
        return "Bad Emails";
      case "junk":
        return "Junk";
      case "unsubscribed":
        return "Unsubscribed";
      case "noEmail":
        return "No Email";
      default:
        return key;
    }
  };

  const handleDeleteIdsFromAllGroup = async () => {
    // console.log("selectedIds=======>",selectedIds)
    const response = await postDeleteFromAllGroupInContactPageAPI({
      groupId: selectedIds,
    });
    if (response.success) {
      handleSaveButtonModal();
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  };

  const handleNavigate = (item) => {
    console.log("item=============>", item);
    navigate(`/contacts/profile/${item?._id}`, {
      state: { data: { contactProfileDetails: item } },
    });
  };

  const handleSorting = (item, index) => {
    let column = item.tableColumnName;

    let temp = contactsAllData.sort((a, b) => {
      if (a[column] < b[column]) {
        return -1;
      }
      if (a[column] > b[column]) {
        return 1;
      }
      return 0;
    });
    setContactsAllData(temp);
    setRefreshPage(!refreshPage);
  };

  const handleSaveButtonModal = () => {
    // console.log("after add modal=============>")
    setSelectedIds([]);
    contactsAllData.map((item) => {
      // console.log("item", item);
      item.isCheck = false;
      // return  item?._id
    });
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveFilterFlag(false);
      }
    };

    if (activeFilterFlag) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeFilterFlag, setActiveFilterFlag]);

  // useEffect(()=>{
  //   console.log("setChooseAFilterArrayDataPayload",chooseAFilterArrayDataPayload)
  //   if(chooseAFilterArrayDataPayload.length>0){
  //     contactsAllGet(pagingData,chooseAFilterArrayDataPayload)
  //   }

  // },[chooseAFilterArrayDataPayload])

  return (
    <div className="allsubscriber">
      {actionId === 1 && (
        <AddModal
          onSuccess={() => {
            handleSaveButtonModal();
          }}
          selectedIds={selectedIds}
          headingText={"Add to group"}
          onClose={() => {
            setActionId(null);
          }}
        />
      )}
      {actionId === 2 && (
        <RemoveModal
          onSuccess={() => {
            handleSaveButtonModal();
          }}
          selectedIds={selectedIds}
          headingText={"Remove from group"}
          onClose={() => {
            setActionId(null);
          }}
        />
      )}
      {actionId === 3 && (
        <GeneralConfirmModal
          onSuccess={handleDeleteIdsFromAllGroup}
          headingText={"Please confirm"}
          onclose={() => {
            setActionId(null);
          }}
          descText={
            "This will delete the selected contacts from your account (from all groups)."
          }
        />
      )}
      <FilterParent
        setChooseAFilterArrayDataPayload={setChooseAFilterArrayDataPayload}
        setViewData={setViewData}
        callApiWithFilter={callApiWithFilter}
        handleActiveList={handleActiveList}
      />

      {/* <AllSUbscriberFilterComponent arrayFilterList={arrayFilterList} setArrayFilterList = {setArrayFilterList} /> */}

      <div className="allsubscriber-content">
        <div className="allsubscriber-content-row-1">
          <ul
            style={{
              padding: "0px",
              listStyle: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <li>{constConfigData?.Showing}</li>
            <li id="allsubscriber-content-row-1-li-2">
              <div
                onClick={() => {
                  allClick();
                  setActiveFilterFlag(!activeFilterFlag);
                }}
              >
                <div style={{ paddingRight: "4px" }}>
                  {selectedActiveFilterItem ? selectedActiveFilterItem : "All"}{" "}
                </div>
                <GoChevronDown />
              </div>
              {activeFilterFlag && (
                <div
                  ref={dropdownRef}
                  className="filter-all"
                  style={{
                    boxShadow: "1px 3px 13px 2px #c1bcbc",
                    // width: "180px",
                    marginTop: "10px",
                    position: "absolute",
                    left: "0",
                    backgroundColor: "#ffffff",
                    zIndex: 300,
                    borderRadius: "4px",
                  }}
                >
                  <div>
                    <ul style={{ padding: "0px", listStyle: "none" }}>
                      {filterCount ? (
                        Object.keys(filterCount).map((key, index) => (
                          <li
                            key={index}
                            className="filterlist-li"
                            onClick={() => {
                              handleActiveList(key);
                            }}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {`${convertFilterCountReadable(key)}  (${
                              filterCount[key]
                            })`}
                          </li>
                        ))
                      ) : (
                        <p style={{ padding: "10px" }}>Loading...</p>
                      )}

                      {/*  {activeList.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="filterlist-li"
                          onClick={() => {
                            handleActiveList(item);
                          }}
                        >
                          {item.activeListKey}
                        </li>
                      );
                    })} */}
                    </ul>
                  </div>
                </div>
              )}
            </li>

            <li>{constConfigData?.Contacts}</li>
          </ul>
          {/*  */}
          <div
            style={{ cursor: "pointer" }}
            className="IsExpanedBased"
            onClick={() => setIsOpen(!isOpen)}
          >
            <div style={{ cursor: "pointer" }} className="IsExpanedBased-inner">
              <div style={{ fontSize: "0.85em", cursor: "pointer" }}>
                {selectedOption}
              </div>{" "}
              <GoChevronDown style={{ cursor: "pointer" }} />
            </div>
            {isOpen && (
              <div className="drop-down">
                {" "}
                {option.map((value) => {
                  return (
                    <div
                      className="drop-down-options"
                      onClick={() => {
                        setSelectedOption(value);
                        changePayload(value);
                      }}
                      style={{ fontSize: "0.85em" }}
                    >
                      {value}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="allsubscriber-content-count">
          <h1>
            {/* {selectedActiveFilterItem?.count
              ? selectedActiveFilterItem?.count
              : tableDataInitialList.length} */}
            {convertNumberIntoUSFromat(totalCount)}
          </h1>
        </div>
        <div
          style={{ padding: "0.5px 0px", backgroundColor: "rgb(216 216 216)" }}
        ></div>
        <div className="allsubscriber-content-action">
          <div
            className="allsubscriber-content-action-dp-hide"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "1vw",
            }}
          >
            <div
              onClick={() => {
                setShowAction(!showAction);
              }}
              className="allsubscriber-content-action-btn"
            >
              <p style={{ marginRight: "10px" }}>{constConfigData?.Actions}</p>
              <GoChevronDown />
            </div>

            {showAction && (
              <div
                style={{
                  boxShadow: "1px 3px 13px 2px #c1bcbc",
                  width: "200px",
                  // marginTop: "30px",
                  position: "absolute",
                  top: "3em",
                  left: "0",
                  backgroundColor: "#ffffff",
                  borderRadius: "4px",
                  zIndex: 200,
                }}
              >
                <ul style={{ padding: "0px", listStyle: "none" }}>
                  {actionList.map((item, index) => {
                    return (
                      <li
                        key={index}
                        style={
                          selectedIds.length > 0 || onSelectVisibleFlag === true
                            ? { color: "black" }
                            : { color: "grey" }
                        }
                        className="filterlist-li"
                        onClick={() => {
                          setShowAction(false);

                          if (selectedIds.length > 0) {
                            setActionId(item?.id);
                            if (item?.id === 4) {
                              console.log("visible");
                              contactExportCsvGet(state);
                            }
                          } else if (onSelectVisibleFlag) {
                            setActionId(item?.id);
                            if (item?.id === 4) {
                              console.log("select all");
                              contactAllExportCsvGet(
                                chooseAFilterArrayDataPayload
                              );
                            }
                          } else {
                            setActionId(null);
                          }
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            // color: item?.id === 4 ? "black" : "inherit",
                          }}
                        >
                          {handleActionIcon(item.iconName)}
                          {item.actionListKey}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {selectedIds.length > 0 ? (
              <div
                style={{
                  color: "rgba(38, 38, 38, 1)",
                  fontWeight: "400",
                  fontSize: "0.875em",
                }}
              >
                You have selected contacts{" "}
                <span style={{ fontWeight: "600" }}>{selectedIds.length} </span>
              </div>
            ) : (
              ""
            )}
            {onSelectVisibleFlag ? (
              <div
                style={{
                  color: "rgba(38, 38, 38, 1)",
                  fontWeight: "400",
                  fontSize: "0.875em",
                }}
              >
                You have selected contacts{" "}
                <span style={{ fontWeight: "600" }}>{totalCount} </span>
              </div>
            ) : (
              ""
            )}
          </div>

          <div
            className="select-header"
            onClick={() => {
              navigate("/constant-config");
            }}
          >
            {constConfigData?.Select_Headers}
          </div>
        </div>

        <div
          style={{ padding: "0.9px 0px", backgroundColor: "rgb(240 239 239)" }}
        ></div>
        <div className="allsubscriber-content-table">
          <table style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "black",
                    // backgroundColor:"green",
                    // minWidth: "50px"
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#E5E7EB",
                      padding: "5px",
                      display: "flex",
                      height: "1.5em",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                    className="select-visible-dropdown"
                    onClick={() => setIsCheck(!isCheck)}
                  >
                    <GoChevronDown />
                    {isCheck && (
                      <ul
                        className="drop-down-check"
                        style={{ listStyle: "none", zIndex: 1 }}
                      >
                        <li
                          className="drop-down-options"
                          onClick={() => {
                            setonSelectVisibleFlag(false);
                            selectAllCheckboxes();
                          }}
                        >
                          Select visible
                        </li>
                        <li
                          className="drop-down-options"
                          onClick={() => {
                            setonSelectVisibleFlag(true);
                            contactSelectAllExport();
                          }}
                        >
                          Select all
                        </li>
                      </ul>
                    )}
                  </div>
                </th>
                {exportAllData.map((item, id) => {
                  // console.log("column head",item)
                  if (item?.canExport) {
                    tableColumnHead.push(item?.tableColumnName);
                    return (
                      <th
                        onClick={() => {
                          handleSorting(item, id);
                        }}
                        key={id}
                        style={{
                          textWrap: "nowrap",
                          // backgroundColor:"red",
                          // width:"500px",
                          textAlign: "left",
                          fontWeight: "500",
                          fontSize: "1em",
                          cursor: "pointer",
                          // marginRight:"100px"
                        }}
                      >
                        {item?.columnName}
                      </th>
                    );
                  }
                })}
              </tr>
            </thead>

            <tbody className="allsubscriber-content-table-tbody">
              {smallLoaderFlag ? (
                <div style={{ width: "825%" }}>
                  <SmallLoaderComponent />
                </div>
              ) : (
                contactsAllData?.map((item, index) => {
                  return (
                    <tr
                      className="border"
                      key={index}
                      style={{
                        backgroundColor: `${
                          handleIsCheckedOddOrEven(index)
                            ? "white"
                            : "rgba(250, 250, 250, 1)"
                        }`,
                        border: "1px solid rgb(240 239 239)",
                        height: "100%",
                      }}
                    >
                      <td>
                        <div
                          style={{
                            padding: "0.8em 0em",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            borderRadius: "4px",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={item?.isCheck}
                            onClick={() => {
                              handleCheckboxChange(
                                index,
                                item?.isCheck,
                                item?._id
                              );
                            }}
                            style={{ borderColor: "rgba(209, 213, 219, 1)" }}
                          />
                        </div>
                      </td>
                      {tableColumnHead.map((head, columnIndex) => {
                        return (
                          <td
                            key={columnIndex}
                            style={{
                              fontWeight: "400",
                              fontSize: "1em",
                              color:
                                columnIndex === 0
                                  ? "#2081E3"
                                  : "rgba(64, 64, 64, 1)",
                              cursor: columnIndex === 0 ? "pointer" : "",
                              /* textWrap: "nowrap", */
                            }}
                            onClick={() => {
                              if (columnIndex === 0) {
                                handleNavigate(item);
                              }
                            }}
                          >
                            {Array.isArray(item[head]) ? (
                              item[head].length === 0 ? (
                                ""
                              ) : (
                                <div>
                                  <div style={{ padding: "0.8em 0em" }}>
                                    {item[head][0]}
                                    {item[head].length > 1 && (
                                      <GoChevronDown
                                        style={{ cursor: "pointer" }}
                                        onClick={() => toggleDropdown(index)}
                                      />
                                    )}
                                  </div>
                                  {dropdownOpen[index] && (
                                    <div
                                      style={{
                                        position: "relative",
                                        zIndex: 400,
                                        padding: "0.8em 0em",
                                      }}
                                    >
                                      {item[head].slice(1).map((value, i) => (
                                        <div key={i} style={{}}>
                                          {value}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              )
                            ) : (
                              item[head]
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>

          {/* Below is created using syncfusion */}

          {/* <SyncFusionTable dataSource={SyncData}/> */}
        </div>

        <div className="allsubscriber-content-item-left">
          <div>
            <p>
              {constConfigData?.Showing}
              {/* {pagingData.page }  */}{" "}
              <span>
                {contactsAllData.length > 0
                  ? pagingData.limit * pagingData.page - (pagingData.limit - 1)
                  : 0}
              </span>{" "}
              to {/* {pagingData.page *historyAllData.length}  */}
              <span>
                {" "}
                {pagingData.page * pagingData.limit > totalCount
                  ? totalCount
                  : pagingData.page * pagingData.limit}
              </span>{" "}
              of <span>{totalCount}</span> {constConfigData?.results}
            </p>
          </div>
          <div>
            <button
              // pagingData.length > 2 ? true : false
              disabled={true && !smallLoaderFlag ? false : true}
              onClick={() => {
                handlePagingApiData(1);
              }}
              style={{
                backgroundColor: true ? "#E5E7EB" : "#F2F3F5",
                // pagingData.skip == 0 ? "#F2F3F5" : "#E5E7EB",
                padding: "12px 22px",
                fontSize: "1.1em",
                color: true ? "#404040" : "rgb(159,159,159)",
                // pagingData.skip == 0?"rgb(159,159,159)":"#404040",
              }}
            >
              {constConfigData?.Previous}
            </button>
            <button
              // disabled={pagingData.length > 2 ? true : false}
              disabled={true && !smallLoaderFlag ? false : true}
              onClick={() => {
                handlePagingApiData(2);
              }}
              style={{
                // backgroundColor:totalCount > 10 * pagingData.page ? "#E5E7EB" : "#F2F3F5",
                backgroundColor: true ? "#E5E7EB" : "#F2F3F5",
                padding: "12px 22px",
                fontSize: "1.1em",
                color: true ? "#404040" : "rgb(159,159,159)",
              }}
            >
              {constConfigData?.Next}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllSubscriber;
